import React from 'react';
import { Box, Typography } from '@mui/material';
import { Class } from '@mui/icons-material';

interface EmptyStateMessageProps {
  status: string;
}

const EmptyStateMessage: React.FC<EmptyStateMessageProps> = ({ status }) => {
  return (
    <Box sx={{ 
      py: 8, 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      color: 'text.secondary'
    }}>
      <Class sx={{ fontSize: 48, mb: 2, opacity: 0.7 }} />
      <Typography variant="h6" gutterBottom>
        No {status} submissions found
      </Typography>
      <Typography variant="body2">
        {status === 'pending' 
          ? "You don't have any submissions waiting for approval." 
          : `You don't have any ${status} submissions yet.`}
      </Typography>
    </Box>
  );
};

export default EmptyStateMessage;
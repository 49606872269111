import { useState } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { format, isValid } from 'date-fns';
import { usePdfGenerator } from './usePdfGenerator';

// Import utilities from your existing files
import { formatDate, formatTime, formatDateRange } from '../components/SubmissionDetail/utils/formatters';
import { 
  isTruthyResponse, 
  getSelectedOptions, 
  getNumericValue,
  hasFollowUpData,
  getFollowUpResponses
} from '../components/SubmissionDetail/utils/responseUtils';
import { normalizeTextResponses } from '../services/firebase/responseProcessing';
import { convertToDate } from '../services/firebase/responseProcessing';

// Update the formatPhoneNumber function to handle UK phone numbers
const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber || typeof phoneNumber !== 'string') return phoneNumber;

  // Remove non-numeric characters
  const cleaned = phoneNumber.replace(/\D/g, '');
  
  // Check if it's a valid length
  if (cleaned.length < 10) return phoneNumber;
  
  // Handle UK mobile numbers (starting with 07)
  if (cleaned.startsWith('07') && cleaned.length === 11) {
    return `${cleaned.slice(0, 5)} ${cleaned.slice(5, 8)} ${cleaned.slice(8)}`;
  }
  
  // Handle UK numbers with +44 prefix
  if (cleaned.startsWith('44') && cleaned.length >= 11) {
    const mainNumber = cleaned.slice(2);
    if (mainNumber.startsWith('7')) { // Mobile
      return `+44 ${mainNumber.slice(0, 4)} ${mainNumber.slice(4, 7)} ${mainNumber.slice(7)}`;
    } else { // Landline
      return `+44 ${mainNumber.slice(0, 3)} ${mainNumber.slice(3, 6)} ${mainNumber.slice(6)}`;
    }
  }
  
  // Handle full UK numbers starting with 0
  if (cleaned.startsWith('0') && cleaned.length === 11) {
    if (cleaned.startsWith('07')) { // Mobile
      return `${cleaned.slice(0, 5)} ${cleaned.slice(5, 8)} ${cleaned.slice(8)}`;
    } else { // Landline
      return `${cleaned.slice(0, 4)} ${cleaned.slice(4, 7)} ${cleaned.slice(7)}`;
    }
  }
  
  // If it looks like an international number but not US/UK
  if (cleaned.length > 10 && (cleaned.startsWith('1') || cleaned.startsWith('2') || 
       cleaned.startsWith('3') || cleaned.startsWith('4') || cleaned.startsWith('5') ||
       cleaned.startsWith('6') || cleaned.startsWith('8') || cleaned.startsWith('9'))) {
    // Try to format as international
    return `+${cleaned.slice(0, 2)} ${cleaned.slice(2, 5)} ${cleaned.slice(5, 8)} ${cleaned.slice(8)}`;
  }
  
  // Handle standard US format as fallback (10 digits)
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }
  
  // Handle US number with country code
  if (cleaned.startsWith('1') && cleaned.length === 11) {
    return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
  }
  
  // If we can't determine the format, return with basic spacing
  if (cleaned.length > 8) {
    // Add spaces every 3-4 digits for readability
    let formatted = '';
    for (let i = 0; i < cleaned.length; i += 4) {
      formatted += cleaned.slice(i, Math.min(i + 4, cleaned.length)) + ' ';
    }
    return formatted.trim();
  }
  
  // Fall back to original if we can't format it
  return phoneNumber;
};

// Add a simple text renderer function
const renderText = (question: any, response: any): string => {
  if (response === undefined || response === null) {
    return '';
  }
  
  // Convert to string if it's not already
  if (typeof response !== 'string') {
    if (typeof response === 'boolean') {
      return response ? 'Yes' : 'No';
    }
    
    if (typeof response === 'object' && response !== null) {
      try {
        return JSON.stringify(response);
      } catch (e) {
        return '[Complex Object]';
      }
    }
    
    return String(response);
  }
  
  return response;
};

// Add the missing convertResponseToDate function
const convertResponseToDate = (dateValue: any): Date | null => {
  if (!dateValue) return null;
  
  try {
    // Handle Firestore timestamp
    if (typeof dateValue === 'object' && typeof dateValue.toDate === 'function') {
      return dateValue.toDate();
    }
    
    // Handle serialized timestamp
    if (typeof dateValue === 'object' && dateValue.seconds !== undefined) {
      return new Date(dateValue.seconds * 1000);
    }
    
    // Handle string or number date
    const date = new Date(dateValue);
    return isNaN(date.getTime()) ? null : date;
  } catch (e) {
    console.error('Error converting date:', e);
    return null;
  }
};

// Define FirestoreTimestamp interface
interface FirestoreTimestamp {
  seconds: number;
  nanoseconds: number;
}

export const useExport = () => {
  const [exporting, setExporting] = useState(false);
  const { generatePdf } = usePdfGenerator();

  // Update the getCommentForQuestion function to be more thorough
const getCommentForQuestion = (questionId: string, submission: any): string => {
  if (!submission) return '';
  
  // Check in dedicated comments object
  if (submission.comments && submission.comments[questionId]) {
    return submission.comments[questionId];
  }
  
  // Check in responses for comment fields
  if (submission.responses) {
    const possibleCommentKeys = [
      `${questionId}_comment`,
      `comment_${questionId}`,
      `${questionId}Comment`,
      `${questionId}-comment`,
      `${questionId}_comments`,
      `comments_${questionId}`
    ];
    
    for (const key of possibleCommentKeys) {
      if (submission.responses[key]) {
        return submission.responses[key];
      }
    }
  }
  
  return '';
};

  // Helper function to get follow-up response for a question
  const getFollowUpResponse = (questionId: string, submission: any): string | null => {
    if (!submission) return null;
    
    const responses = submission.responses || {};
    
    // Check all possible locations where follow-ups might be stored
    if (responses[`${questionId}_followUp`]) {
      return responses[`${questionId}_followUp`];
    }
    
    if (responses[`followUp_${questionId}`]) {
      return responses[`followUp_${questionId}`];
    }
    
    if (responses[`${questionId}followUp`]) {
      return responses[`${questionId}followUp`];
    }
    
    return null;
  };

  // Helper function to get option-specific follow-up responses
  const getOptionFollowUp = (questionId: string, optionValue: string, submission: any): string | null => {
    if (!submission) return null;
    
    const responses = submission.responses || {};
    
    // Check all possible locations for option-specific follow-ups
    const possibleKeys = [
      `${questionId}_followUp_${optionValue}`,
      `followUp_${questionId}_${optionValue}`,
      `${questionId}_option_${optionValue}_followUp`,
      `${questionId}${optionValue}followUp`
    ];
    
    for (const key of possibleKeys) {
      if (responses[key] !== undefined && responses[key] !== null) {
        return responses[key];
      }
    }
    
    return null;
  };

  // Helper function to get "Other" response for a question
  const getOtherResponse = (questionId: string, submission: any): string | null => {
    if (!submission) return null;
    
    const responses = submission.responses || {};
    
    // Check all possible locations for "Other" responses
    const possibleKeys = [
      `${questionId}_other`, 
      `other_${questionId}`,
      `${questionId}other`
    ];
    
    for (const key of possibleKeys) {
      if (responses[key] !== undefined && responses[key] !== null) {
        return responses[key];
      }
    }
    
    return null;
  };

  // Helper function to format responses consistently using the new renderer system
  const formatResponseForExport = (question: any, submission: any): string => {
    const responses = submission?.responses || {};
    const response = responses[question.id];

    if (response === undefined || response === null) {
      return '';
    }

    try {
      switch (question.type) {
        case 'date':
          return formatDate(response);
        
        case 'date-range':
          return formatDateRange(response);
          
        case 'time':
          return formatTime(response);
        
        case 'text':
        case 'long-text':
          // Handle phone number formatting for phone fields
          if (
            question.inputType === 'phone' || 
            question.inputType === 'tel' ||
            (typeof response === 'string' && 
              (question.label?.toLowerCase().includes('phone') || 
               question.label?.toLowerCase().includes('mobile') || 
               question.label?.toLowerCase().includes('contact') || 
               question.label?.toLowerCase().includes('telephone'))
            )
          ) {
            // Format UK phone numbers
            return formatPhoneNumber(response);
          }
          return String(response || '');
          
        case 'checkbox':
          if (question.checkboxStyle === 'yes-no') {
            const isTrue = isTruthyResponse(response);
            return isTrue ? 'Yes' : 'No';
          } else if (question.checkboxStyle === 'single') {
            return isTruthyResponse(response) ? 'Checked' : 'Unchecked';
          } else {
            // Multi-checkbox
            const options = getSelectedOptions(response);
            return options.join(', ');
          }
          
        case 'select':
          return String(response || '');
          
        case 'multi-select':
          const multiOptions = getSelectedOptions(response);
          return multiOptions.join(', ');
          
        case 'repeatable':
          // First try to get items from repeatableItems (preferred location)
          let items = submission?.repeatableItems?.[question.id];
          
          // If not found there, check in responses
          if (!items || !Array.isArray(items) || items.length === 0) {
            items = Array.isArray(responses[question.id]) ? responses[question.id] : [];
          }
          
          // Also check for object with numeric keys pattern
          if ((!items || items.length === 0) && typeof response === 'object' && response !== null) {
            const possibleArrayItems = Object.keys(response)
              .filter(key => !isNaN(Number(key)))
              .map(key => response[key]);
              
            if (possibleArrayItems.length > 0) {
              items = possibleArrayItems;
            }
          }
          
          if (!items || !Array.isArray(items) || items.length === 0) {
            return 'No items';
          }
          
          // Format each repeatable item into a structured string optimized for Excel
          return items.map((item, index) => {
            const itemParts: string[] = [];
            
            // Format each subfield
            if (question.subFields && Array.isArray(question.subFields)) {
              question.subFields.forEach((subField: any) => {
                const subValue = item[subField.id];
                if (subValue !== undefined && subValue !== null) {
                  // Format phone numbers in subfields too
                  let formattedValue = subValue;
                  if (
                    subField.inputType === 'phone' || 
                    subField.inputType === 'tel' ||
                    (typeof subValue === 'string' && 
                      (subField.label?.toLowerCase().includes('phone') || 
                      subField.label?.toLowerCase().includes('mobile') || 
                      subField.label?.toLowerCase().includes('contact') || 
                      subField.label?.toLowerCase().includes('telephone'))
                    )
                  ) {
                    formattedValue = formatPhoneNumber(subValue);
                  }
                  
                  // Format other types appropriately
                  if (typeof formattedValue === 'boolean') {
                    formattedValue = formattedValue ? 'Yes' : 'No';
                  } else if (Array.isArray(formattedValue)) {
                    formattedValue = formattedValue.join(', ');
                  } else if (formattedValue instanceof Date) {
                    formattedValue = format(formattedValue, 'MMM d, yyyy');
                  } else if (typeof formattedValue === 'object' && formattedValue !== null) {
                    // Handle date objects from Firestore
                    if (formattedValue.seconds) {
                      formattedValue = format(new Date(formattedValue.seconds * 1000), 'MMM d, yyyy');
                    } else {
                      try {
                        formattedValue = JSON.stringify(formattedValue);
                      } catch (e) {
                        formattedValue = '[Complex Object]';
                      }
                    }
                  }
                  
                  itemParts.push(`${subField.label}: ${formattedValue}`);
                }
              });
            } else {
              // If subFields isn't defined, just show key-value pairs
              Object.entries(item).forEach(([key, value]) => {
                if (value !== undefined && value !== null && key !== 'id') {
                  itemParts.push(`${key}: ${value}`);
                }
              });
            }
            
            // Join all parts for this item
            return `Item ${index + 1}: {${itemParts.join('; ')}}`;
          }).join('\n');
          
        case 'number':
          // Format number response
          const numValue = getNumericValue(response);
          return numValue !== null ? String(numValue) : '';
          
        case 'rating':
          // Format rating response
          return response !== null && response !== undefined ? String(response) : '';
          
        default:
          return renderText(question, response) || '';
      }
    } catch (err) {
      console.error(`Error formatting response for question ${question.id}:`, err);
      return 'Error formatting response';
    }
  };

  // Export individual submission to PDF
  const exportSubmissionToPdf = async (
    formId: string,
    form: any,
    submission: any,
    theme: any
  ) => {
    setExporting(true);
    try {
      // Prepare all data including follow-ups and comments
      const preparedSubmission = {
        responses: submission.responses || {},
        repeatableItems: submission.repeatableItems || {},
        signatures: submission.signatures || {},
        imagePreview: submission.imagePreview || {},
        annotatedImages: submission.annotatedImages || {},
        comments: submission.comments || {}, // Explicitly include comments
      };
      
      // Add comment data to responses if they exist separately
      if (submission.comments) {
        Object.entries(submission.comments).forEach(([questionId, comment]) => {
          if (comment) {
            preparedSubmission.responses[`${questionId}_comment`] = comment;
          }
        });
      }
      
      // Generate PDF with the modular system
      const doc = generatePdf(
        formId,
        form,
        preparedSubmission.responses,
        preparedSubmission.repeatableItems,
        preparedSubmission.signatures,
        preparedSubmission.imagePreview,
        preparedSubmission.annotatedImages,
        form.companyLogo || null,
        theme
      );
      
      // Create a meaningful filename
      const safeFormTitle = (form.formTitle || 'Form')
        .replace(/[^a-zA-Z0-9-_]/g, '_')
        .replace(/_+/g, '_');
      const timestamp = format(new Date(), 'yyyyMMdd_HHmm');
      
      // Save the PDF
      doc.save(`${safeFormTitle}_${submission.id}_${timestamp}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setExporting(false);
    }
  };

  // Export all submissions to CSV
  const exportSubmissionsToCSV = (
    formTitle: string,
    questions: any[],
    submissions: any[]
  ) => {
    setExporting(true);
    try {
      console.log("Starting CSV export for", submissions.length, "submissions");
      
      // Create headers for the CSV
      const headers = ['Submission ID', 'Email', 'Submission Date'];
      
      // Add question labels as headers, excluding specific types
      const exportableQuestions = questions.filter(q => 
        !['instructions', 'section', 'image', 'signature', 'file'].includes(q.type)
      );
      
      // Create a map to keep track of column headers to their positions
      const headerMap = new Map();
      headerMap.set('Submission ID', 0);
      headerMap.set('Email', 1);
      headerMap.set('Submission Date', 2);
      
      // Add standard question columns and additional columns for associated data
      exportableQuestions.forEach(question => {
        // Main question column
        headers.push(question.label);
        headerMap.set(`question_${question.id}`, headers.length - 1);
        
        // Comment column (always include it to ensure we catch all comment types)
        headers.push(`${question.label} - Comment`);
        headerMap.set(`comment_${question.id}`, headers.length - 1);
        
        // Add "Other" option column where applicable
        if ((question.type === 'select' || question.type === 'multi-select' || 
             (question.type === 'checkbox' && question.checkboxStyle === 'multi')) && 
            question.allowOther) {
          headers.push(`${question.label} - Other option`);
          headerMap.set(`other_${question.id}`, headers.length - 1);
        }
        
        // Add follow-up column for yes/no checkboxes
        if (question.type === 'checkbox' && question.checkboxStyle === 'yes-no' && 
            (question.followUpEnabled || question.showFollowUp)) {
          headers.push(`${question.label} - Follow-up`);
          headerMap.set(`followUp_${question.id}`, headers.length - 1);
        }
        
        // Add option-specific follow-up columns for select/multi-select questions
        if ((question.type === 'select' || question.type === 'multi-select') && 
            Array.isArray(question.options)) {
          question.options.forEach((option: { followUp: any; label: any; value: any; }) => {
            if (option.followUp) {
              headers.push(`${question.label} - ${option.label || option.value} follow-up`);
              headerMap.set(`followUp_${question.id}_${option.value}`, headers.length - 1);
            }
          });
        }
      });

      // Create rows for each submission
      const rows = submissions.map(submission => {
        // Create an array filled with empty strings for all columns
        const row = new Array(headers.length).fill('');
        
        // Add metadata columns
        const submissionDate = submission.submittedAt?.toDate
          ? submission.submittedAt.toDate()
          : convertResponseToDate(submission.submittedAt);
            
        row[headerMap.get('Submission ID')] = submission.id;
        row[headerMap.get('Email')] = submission.email || 'Anonymous';
        row[headerMap.get('Submission Date')] = format(submissionDate, 'MMM d, yyyy h:mm a');
        
        // Add responses for each question and its associated data
        exportableQuestions.forEach(question => {
          const questionId = question.id;
          
          // Add main response
          if (headerMap.has(`question_${questionId}`)) {
            row[headerMap.get(`question_${questionId}`)] = formatResponseForExport(question, submission);
          }
          
          // Add comment if present
          if (headerMap.has(`comment_${questionId}`)) {
            const comment = getCommentForQuestion(questionId, submission);
            if (comment) {
              row[headerMap.get(`comment_${questionId}`)] = comment;
            }
          }
          
          // Add "Other" response if present
          if (headerMap.has(`other_${questionId}`)) {
            const otherResponse = getOtherResponse(questionId, submission);
            if (otherResponse) {
              row[headerMap.get(`other_${questionId}`)] = otherResponse;
            }
          }
          
          // Add follow-up for yes/no checkboxes
          if (headerMap.has(`followUp_${questionId}`)) {
            const followUpResponse = getFollowUpResponse(questionId, submission);
            if (followUpResponse) {
              row[headerMap.get(`followUp_${questionId}`)] = followUpResponse;
            }
          }
          
          // Add option-specific follow-ups for select/multi-select
          if ((question.type === 'select' || question.type === 'multi-select') && 
              Array.isArray(question.options)) {
            question.options.forEach((option: { value: string; }) => {
              const followUpKey = `followUp_${questionId}_${option.value}`;
              if (headerMap.has(followUpKey)) {
                const optionFollowUp = getOptionFollowUp(questionId, option.value, submission);
                if (optionFollowUp) {
                  row[headerMap.get(followUpKey)] = optionFollowUp;
                }
              }
            });
          }
        });
        
        return row;
      });

      // Create worksheet with headers and rows
      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
      
      // Auto-size columns
      worksheet['!cols'] = Array(headers.length).fill({ wch: 22 }); // Wider columns for better readability
      
      // Create workbook and add worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Responses');
      
      // Generate CSV file
      const csvOutput = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
      const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8' });
      
      // Save CSV file
      saveAs(blob, `${formTitle || 'Form'}_Responses.csv`);
      console.log("CSV export completed successfully");
    } catch (error) {
      console.error('Error generating CSV:', error);
    } finally {
      setExporting(false);
    }
  };

  // Fix the exportSubmissionsToExcel function definition:
const exportSubmissionsToExcel = (
  formTitle: string, 
  questions: any[], 
  submissions: any[]
) => {
  setExporting(true);
  try {
    console.log("Starting Excel export for", submissions.length, "submissions");
    
    // Create headers for the Excel file
    const headers = ['Submission ID', 'Email', 'Submission Date'];
    
    // Create a map to keep track of column headers to their positions
    const headerMap = new Map();
    headerMap.set('Submission ID', 0);
    headerMap.set('Email', 1);
    headerMap.set('Submission Date', 2);
    
    // Create a map to track sections for prefixing child question labels
    const sectionMap = new Map();
    const sectionQuestionMap = new Map();
    
    // First pass: Identify sections and their child questions
    questions.forEach(question => {
      if (question.type === 'section') {
        sectionMap.set(question.id, question.label || 'Section');
      }
      
      // Map questions to their sections
      if (question.sectionId) {
        sectionQuestionMap.set(question.id, question.sectionId);
      }
    });
    
    // Add question labels as headers, now including sections for better organization
    const exportableQuestions = questions.filter(q => 
      !['instructions', 'image'].includes(q.type) // Allow sections, but exclude other non-data types
    );
    
    // Process questions for columns, including section-based formatting
    exportableQuestions.forEach(question => {
      // For section questions, we'll extract data in a special way
      if (question.type === 'section') {
        // For the section itself, we'll add a column to capture any direct responses
        const sectionHeader = `${question.label || 'Section'}`;
        headers.push(sectionHeader);
        headerMap.set(`question_${question.id}`, headers.length - 1);
        
        // Add column for section comments
        headers.push(`${sectionHeader} - Comment`);
        headerMap.set(`comment_${question.id}`, headers.length - 1);
        
        // Look for section_q_[number] pattern questions
        const sectionKeyPattern = new RegExp(`^section_q_(\\d+)$`);
        const sectionQuestions = new Set();
        
        // Find all section question IDs in the submissions
        submissions.forEach(submission => {
          if (submission.responses) {
            Object.keys(submission.responses).forEach(key => {
              const match = key.match(sectionKeyPattern);
              if (match && match[1]) {
                sectionQuestions.add(match[1]);
              }
            });
          }
        });
        
        // Add columns for each section question found
        Array.from(sectionQuestions).forEach(sectionQuestionId => {
          const sectionQuestionLabel = `${sectionHeader}: Question ${sectionQuestionId}`;
          headers.push(sectionQuestionLabel);
          headerMap.set(`section_q_${sectionQuestionId}`, headers.length - 1);
          
          // Also add a column for comments on this section question
          headers.push(`${sectionQuestionLabel} - Comment`);
          headerMap.set(`section_q_${sectionQuestionId}_comment`, headers.length - 1);
        });
      } else {
        // For regular questions, prefix with section name if applicable
        let prefix = '';
        
        // If this question belongs to a section, prefix with section name
        if (question.sectionId && sectionMap.has(question.sectionId)) {
          prefix = `${sectionMap.get(question.sectionId)}: `;
        }
        
        // Main question column
        const questionLabel = `${prefix}${question.label || question.id}`;
        headers.push(questionLabel);
        headerMap.set(`question_${question.id}`, headers.length - 1);
        
        // Comment column (always include it)
        headers.push(`${questionLabel} - Comment`);
        headerMap.set(`comment_${question.id}`, headers.length - 1);
        
        // Add "Other" option column where applicable
        if ((question.type === 'select' || question.type === 'multi-select' || 
            (question.type === 'checkbox' && question.checkboxStyle === 'multi')) && 
            question.allowOther) {
          headers.push(`${questionLabel} - Other option`);
          headerMap.set(`other_${question.id}`, headers.length - 1);
        }
        
        // Add follow-up column for yes/no checkboxes
        if (question.type === 'checkbox' && question.checkboxStyle === 'yes-no' && 
            (question.followUpEnabled || question.showFollowUp)) {
          headers.push(`${questionLabel} - Follow-up`);
          headerMap.set(`followUp_${question.id}`, headers.length - 1);
        }
        
        // Add option-specific follow-up columns for select/multi-select questions
        if ((question.type === 'select' || question.type === 'multi-select') && 
            Array.isArray(question.options)) {
          question.options.forEach((option: { followUp: any; label: any; value: any; }) => {
            if (option.followUp) {
              headers.push(`${questionLabel} - ${option.label || option.value} follow-up`);
              headerMap.set(`followUp_${question.id}_${option.value}`, headers.length - 1);
            }
          });
        }
      }
    });

    // Create rows for each submission
    const rows = submissions.map(submission => {
      // Create an array filled with empty strings for all columns
      const row = new Array(headers.length).fill('');
      
      // Add metadata columns
      const submissionDate = submission.submittedAt?.toDate
        ? submission.submittedAt.toDate()
        : convertResponseToDate(submission.submittedAt);
          
      row[headerMap.get('Submission ID')] = submission.id || '';
      row[headerMap.get('Email')] = submission.email || 'Anonymous';
      row[headerMap.get('Submission Date')] = submissionDate ? format(submissionDate, 'MMM d, yyyy h:mm a') : '';
      
      // Add standard question responses
      exportableQuestions.forEach(question => {
        const questionId = question.id;
        
        if (question.type === 'section') {
          // Handle section direct responses (if any)
          if (headerMap.has(`question_${questionId}`)) {
            const sectionResponse = submission.responses?.[questionId] || '';
            row[headerMap.get(`question_${questionId}`)] = sectionResponse;
          }
          
          // Handle section comments
          if (headerMap.has(`comment_${questionId}`)) {
            const comment = getCommentForQuestion(questionId, submission);
            row[headerMap.get(`comment_${questionId}`)] = comment;
          }
          
          // Handle special section_q_[number] responses
          if (submission.responses) {
            Object.entries(submission.responses).forEach(([key, value]) => {
              // Match section_q_[number] pattern
              const match = key.match(/^section_q_(\d+)$/);
              if (match && match[1]) {
                const sectionQuestionId = match[1];
                
                // Add the section question response
                if (headerMap.has(`section_q_${sectionQuestionId}`)) {
                  row[headerMap.get(`section_q_${sectionQuestionId}`)] = 
                    typeof value === 'string' ? value : JSON.stringify(value);
                }
                
                // Add any comments for this section question
                if (headerMap.has(`section_q_${sectionQuestionId}_comment`)) {
                  const commentKey = `section_q_${sectionQuestionId}_comment`;
                  const comment = submission.responses[commentKey] || 
                                 submission.comments?.[`section_q_${sectionQuestionId}`] || '';
                  
                  row[headerMap.get(`section_q_${sectionQuestionId}_comment`)] = comment;
                }
              }
            });
          }
        } else {
          // Handle standard question responses
          
          // Add main response
          if (headerMap.has(`question_${questionId}`)) {
            row[headerMap.get(`question_${questionId}`)] = formatResponseForExport(question, submission);
          }
          
          // Add comment if present
          if (headerMap.has(`comment_${questionId}`)) {
            const comment = getCommentForQuestion(questionId, submission);
            row[headerMap.get(`comment_${questionId}`)] = comment;
          }
          
          // Add "Other" response if present
          if (headerMap.has(`other_${questionId}`)) {
            const otherResponse = getOtherResponse(questionId, submission);
            row[headerMap.get(`other_${questionId}`)] = otherResponse || '';
          }
          
          // Add follow-up for yes/no checkboxes
          if (headerMap.has(`followUp_${questionId}`)) {
            const followUpResponse = getFollowUpResponse(questionId, submission);
            row[headerMap.get(`followUp_${questionId}`)] = followUpResponse || '';
          }
          
          // Add option-specific follow-ups for select/multi-select
          if ((question.type === 'select' || question.type === 'multi-select') && 
              Array.isArray(question.options)) {
            question.options.forEach((option: { value: string; }) => {
              const followUpKey = `followUp_${questionId}_${option.value}`;
              if (headerMap.has(followUpKey)) {
                const optionFollowUp = getOptionFollowUp(questionId, option.value, submission);
                row[headerMap.get(followUpKey)] = optionFollowUp || '';
              }
            });
          }
        }
      });
      
      return row;
    });

    // Create worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    
    // Apply styling to headers
    const range = XLSX.utils.decode_range(worksheet['!ref'] || 'A1');
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + '1';
      if (!worksheet[address]) continue;
      worksheet[address].s = {
        font: { bold: true },
        fill: { fgColor: { rgb: 'E9E9E9' } }
      };
      
      // Make section header cells more distinctive with a different color
      const headerText = headers[C];
      if (headerText && !headerText.includes(':') && 
          !['Submission ID', 'Email', 'Submission Date'].includes(headerText) && 
          !headerText.includes(' - ')) {
        // This is likely a section header
        worksheet[address].s = {
          font: { bold: true },
          fill: { fgColor: { rgb: 'D0E0FC' } } // Light blue for section headers
        };
      }
    }
    
    // Auto-size columns
    worksheet['!cols'] = Array(headers.length).fill({ wch: 22 }); // Wider columns for better readability
    
    // Create workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Responses');
    
    // Add repeatable items to a separate sheet
    addRepeatableItemsSheet(workbook, questions, submissions);
    
    // Add a separate sheet for section questions with more detail
    addSectionQuestionsSheet(workbook, questions, submissions);
    
    // Generate Excel file
    const excelOutput = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelOutput], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Save Excel file
    saveAs(blob, `${formTitle || 'Form'}_Responses.xlsx`);
    console.log("Excel export completed successfully");
  } catch (error) {
    console.error('Error generating Excel file:', error);
  } finally {
    setExporting(false);
  }
};

  // Add this new function to export repeatable items to a separate sheet
  const addRepeatableItemsSheet = (
    workbook: any,
    questions: any[],
    submissions: any[]
  ) => {
    // Find all repeatable questions
    const repeatableQuestions = questions.filter(q => q.type === 'repeatable');
    
    // If we don't have any repeatable questions, we can skip this
    if (repeatableQuestions.length === 0) return;
    
    repeatableQuestions.forEach(question => {
      // Skip if no subfields are defined
      if (!question.subFields || !Array.isArray(question.subFields) || question.subFields.length === 0) return;
      
      // Create headers for this question's sheet
      const headers = ['Submission ID', 'Email', 'Submission Date', 'Item Index'];
      
      // Add subfield headers
      question.subFields.forEach((subField: {
        label?: string;
        id: string;
      }) => {
        headers.push(subField.label || subField.id);
      });
      
      // Collect all items from all submissions
      const allItems: any[] = [];
      
      submissions.forEach(submission => {
        // Get submission metadata
        const submissionId = submission.id;
        const email = submission.email || 'Anonymous';
        const submissionDate = submission.submittedAt?.toDate
          ? submission.submittedAt.toDate()
          : convertResponseToDate(submission.submittedAt);
        const submissionDateStr = format(submissionDate || new Date(), 'MMM d, yyyy h:mm a');
        
        // Try to get items from repeatableItems (preferred location)
        let items = submission?.repeatableItems?.[question.id];
        
        // If not found there, check in responses
        if (!items || !Array.isArray(items) || items.length === 0) {
          items = Array.isArray(submission.responses?.[question.id]) 
            ? submission.responses[question.id] 
            : [];
        }
        
        // Also check for object with numeric keys pattern
        if ((!items || items.length === 0) && 
            typeof submission.responses?.[question.id] === 'object' && 
            submission.responses?.[question.id] !== null) {
          const response = submission.responses[question.id];
          const possibleArrayItems = Object.keys(response)
            .filter(key => !isNaN(Number(key)))
            .map(key => response[key]);
            
          if (possibleArrayItems.length > 0) {
            items = possibleArrayItems;
          }
        }
        
        // If we found items, add them to our collection
        if (items && Array.isArray(items) && items.length > 0) {
          items.forEach((item, index) => {
            // Add one row per item with metadata
            allItems.push({
              submissionId,
              email,
              submissionDate: submissionDateStr,
              itemIndex: index + 1,
              ...item
            });
          });
        }
      });
      
      // If we have items, create a worksheet
      if (allItems.length > 0) {
        // Convert items to rows
        const rows = allItems.map(item => {
          const row: any[] = [
            item.submissionId,
            item.email,
            item.submissionDate,
            item.itemIndex
          ];
          
          // Add data for each subfield
            question.subFields.forEach((subField: { id: string; }) => {
            let value: string | boolean | Date | any[] | object | null = item[subField.id];
            
            // Format the value appropriately
            if (value === undefined || value === null) {
              value = '';
            } else if (typeof value === 'boolean') {
              value = value ? 'Yes' : 'No';
            } else if (Array.isArray(value)) {
              value = value.join(', ');
            } else if (value instanceof Date) {
              value = format(value, 'MMM d, yyyy');
            } else if (typeof value === 'object' && value !== null) {
              // Handle date objects from Firestore
              if ('seconds' in value && typeof value.seconds === 'number') {
              value = format(new Date(value.seconds * 1000), 'MMM d, yyyy');
              } else {
              try {
                value = JSON.stringify(value);
              } catch (e) {
                value = '[Complex Object]';
              }
              }
            }
            
            row.push(value);
            });
          
          return row;
        });
        
        // Create worksheet
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
        
        // Apply styling to headers
        const range = XLSX.utils.decode_range(worksheet['!ref'] || 'A1');
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_col(C) + '1';
          if (!worksheet[address]) continue;
          worksheet[address].s = {
            font: { bold: true },
            fill: { fgColor: { rgb: 'E9E9E9' } }
          };
        }
        
        // Auto-size columns
        worksheet['!cols'] = Array(headers.length).fill({ wch: 18 });
        
        // Add the worksheet to the workbook
        const safeSheetName = (question.label || `Repeatable ${question.id}`)
          .replace(/[^a-zA-Z0-9-_]/g, '_')
          .substring(0, 31); // Excel has a 31 character limit for sheet names
        
        XLSX.utils.book_append_sheet(workbook, worksheet, safeSheetName);
      }
    });
    
    return workbook;
  };

// Add a new function to export section questions to a dedicated sheet
const addSectionQuestionsSheet = (
  workbook: any,
  questions: any[],
  submissions: any[]
) => {
  // Find all section questions
  const sectionQuestions = questions.filter(q => q.type === 'section');
  
  // If we don't have any section questions, we can skip this
  if (sectionQuestions.length === 0) return;
  
  // Extract all section_q_[number] patterns from all submissions
  const sectionResponseMapping = new Map<string, Set<string>>();
  
  sectionQuestions.forEach(section => {
    sectionResponseMapping.set(section.id, new Set());
  });
  
  // Scan all submissions for section_q_[number] patterns
  submissions.forEach(submission => {
    if (submission.responses) {
      Object.keys(submission.responses).forEach(key => {
        const match = key.match(/^section_q_(\d+)$/);
        if (match && match[1]) {
          const sectionQuestionId = match[1];
          // Add to all sections for now, we'll filter later as needed
          sectionQuestions.forEach(section => {
            const sectionIds = sectionResponseMapping.get(section.id);
            if (sectionIds) {
              sectionIds.add(sectionQuestionId);
            }
          });
        }
      });
    }
  });
  
  // For each section, create a dedicated sheet with all its response data
  sectionQuestions.forEach(section => {
    const sectionQuestionIds = sectionResponseMapping.get(section.id);
    
    if (!sectionQuestionIds || sectionQuestionIds.size === 0) {
      console.log(`No responses found for section ${section.id}`);
      return; // Skip this section if no responses
    }
    
    // Create headers for this section's sheet
    const headers = ['Submission ID', 'Email', 'Submission Date'];
    
    // Add a column for each section question
    Array.from(sectionQuestionIds).forEach(sectionQuestionId => {
      headers.push(`Question ${sectionQuestionId}`);
    });
    
    // Collect all items from all submissions
    const rows: any[] = [];
    
    submissions.forEach(submission => {
      if (!submission.responses) return;
      
      // Check if this submission has any section responses
      const hasSectionResponses = Object.keys(submission.responses).some(key => 
        key.match(/^section_q_\d+$/)
      );
      
      if (!hasSectionResponses) return;
      
      // Create a row for this submission
      const row = [
        submission.id || 'Unknown',
        submission.email || 'Anonymous',
        submission.submittedAt 
          ? format(convertResponseToDate(submission.submittedAt) || new Date(), 'MMM d, yyyy h:mm a')
          : 'Unknown date'
      ];
      
      // Add data for each section question
      Array.from(sectionQuestionIds).forEach(sectionQuestionId => {
        const key = `section_q_${sectionQuestionId}`;
        let value = submission.responses[key] || '';
        
        // Format value appropriately
        if (typeof value === 'object' && value !== null) {
          try {
            value = JSON.stringify(value);
          } catch (e) {
            value = '[Complex Object]';
          }
        }
        
        row.push(value);
      });
      
      rows.push(row);
    });
    
    // If we have rows, create a worksheet
    if (rows.length > 0) {
      // Create worksheet
      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
      
      // Apply styling to headers
      const range = XLSX.utils.decode_range(worksheet['!ref'] || 'A1');
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + '1';
        if (!worksheet[address]) continue;
        worksheet[address].s = {
          font: { bold: true },
          fill: { fgColor: { rgb: 'E9E9E9' } }
        };
      }
      
      // Add the worksheet to the workbook
      const safeSheetName = (section.label || `Section ${section.id}`)
        .replace(/[^a-zA-Z0-9-_]/g, '_')
        .substring(0, 31); // Excel has a 31 character limit for sheet names
      
      XLSX.utils.book_append_sheet(workbook, worksheet, safeSheetName);
    }
  });
  
  return workbook;
};

  // Function to export submissions to CSV
  const exportSubmissionsToCsv = (
    formTitle: string,
    questions: any[],
    submissions: any[]
  ) => {
    setExporting(true);
    try {
      console.log("Starting CSV export for", submissions.length, "submissions");
      
      // Filter to include sections but exclude non-data types
      const exportableQuestions = questions.filter(q => 
        !['instructions', 'image'].includes(q.type)
      );
      
      // Create a map to track sections for prefixing child question labels
      const sectionMap = new Map();
      
      // Identify sections for prefixing
      questions.forEach(question => {
        if (question.type === 'section') {
          sectionMap.set(question.id, question.label || 'Section');
        }
      });
      
      // Create headers with prefixed section names
      const headers = [
        'Submission ID',
        'Email',
        'Submission Date'
      ];
      
      // Add question labels with section prefixes
      exportableQuestions.forEach(question => {
        let prefix = '';
        
        // If this is a section, no prefix
        if (question.type === 'section') {
          headers.push(question.label || 'Section');
        } else {
          // If this question belongs to a section, prefix with section name
          if (question.sectionId && sectionMap.has(question.sectionId)) {
            prefix = `${sectionMap.get(question.sectionId)}: `;
          }
          
          headers.push(`${prefix}${question.label || question.id}`);
        }
      });
      
      // Add general comments
      headers.push('General Comments');
      
      // Create rows for each submission
      const rows = submissions.map(submission => {
        // Format submission date
        let submissionDate = '';
        try {
          const dateObj = submission.submittedAt?.toDate ? 
            submission.submittedAt.toDate() : 
            convertResponseToDate(submission.submittedAt);
            
          submissionDate = dateObj ? format(dateObj, 'MMM d, yyyy h:mm a') : 'Unknown date';
        } catch (e) {
          submissionDate = 'Unknown date';
        }
        
        // Create base row with metadata
        const row = [
          submission.id || 'Unknown',
          submission.email || 'Anonymous',
          submissionDate
        ];
        
        // Add responses for each question
        exportableQuestions.forEach(question => {
          // For section questions, check for direct responses or section_q_[id] patterns
          if (question.type === 'section') {
            // Check for direct section response
            let sectionValue = submission.responses?.[question.id] || '';
            
            // If no direct response, look for section_q_[id] patterns
            if (!sectionValue) {
              const sectionValues: string[] = [];
              
              if (submission.responses) {
                Object.entries(submission.responses).forEach(([key, value]) => {
                  if (key.startsWith('section_q_')) {
                    sectionValues.push(`${key.replace('section_q_', 'Q')}: ${value}`);
                  }
                });
              }
              
              sectionValue = sectionValues.join('; ');
            }
            
            row.push(sectionValue);
          } else {
            // Standard question response
            row.push(formatResponseForExport(question, submission));
          }
        });
        
        // Add general comments
        row.push(submission.generalComments || '');
        
        return row;
      });
      
      // Create CSV content
      const csvContent = [headers, ...rows]
        .map(row => 
          row.map(cell => {
            // Wrap cells with commas or quotes in quotes and escape internal quotes
            const cellStr = String(cell || '');
            if (cellStr.includes(',') || cellStr.includes('"') || cellStr.includes('\n')) {
              return `"${cellStr.replace(/"/g, '""')}"`;
            }
            return cellStr;
          }).join(',')
        )
        .join('\n');
      
      // Create blob and download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `${formTitle || 'Form'}_Responses.csv`);
      console.log("CSV export completed successfully");
    } catch (error) {
      console.error('Error generating CSV file:', error);
    } finally {
      setExporting(false);
    }
  };

  return {
    exporting,
    exportSubmissionsToPdf: generatePdf,
    exportSubmissionsToExcel,
    exportSubmissionsToCsv
  };
};
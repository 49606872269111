// Fix for src/components/AdminTools/FixApprovalData.tsx

import React, { useState } from 'react';
import { 
  Box, Button, CircularProgress, Alert, Dialog, 
  DialogTitle, DialogContent, DialogContentText, 
  DialogActions, Typography, Chip, LinearProgress,
  Paper, TextField
} from '@mui/material';
import { AdminPanelSettings, Build, BuildCircle, Archive as ArchiveIcon, Search as SearchIcon, Sync as SyncIcon } from '@mui/icons-material';
import { 
  collection, query, where, getDocs, writeBatch, 
  doc, getDoc, Timestamp
} from 'firebase/firestore';
import { db } from '../../firebase';
import { fixMissingArchivedField, checkSpecificSubmission, fixSpecificSubmission, checkAllSubmissionsForForm, syncSubmissionCollections } from '../../services/firebase/approvalService';

interface FixApprovalDataProps {
  isAdmin: boolean;
}

const FixApprovalData: React.FC<FixApprovalDataProps> = ({ isAdmin }) => {
  // Declare all hooks first, before any conditional logic
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [results, setResults] = useState<{
    formsProcessed: number;
    submissionsUpdated: number;
    submissionsScanned?: number;
    errors: number;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [operationDetails, setOperationDetails] = useState<string[]>([]);
  const [showDetails, setShowDetails] = useState(false);
  const [confirmAction, setConfirmAction] = useState<(() => Promise<void>) | null>(null);
  const [specificSubmissionId, setSpecificSubmissionId] = useState<string>('');
  const [specificFormId, setSpecificFormId] = useState<string>('');

  // Only after all hooks are declared, we can use conditional return
  if (!isAdmin) return null;
  
  const addOperationDetail = (detail: string) => {
    setOperationDetails(prev => [...prev, detail]);
  };

  const fixApprovalData = async () => {
    setLoading(true);
    setError(null);
    setResults(null);
    setOperationDetails([]);
    setProgress(0);

    try {
      const stats = {
        formsProcessed: 0,
        submissionsUpdated: 0,
        errors: 0
      };

      // Log operation start
      addOperationDetail("🔍 Looking for forms with requiresApproval=true...");

      // 1. Find all forms that require approval
      const formsQuery = query(
        collection(db, "forms"),
        where("requiresApproval", "==", true)
      );
      
      const formsSnapshot = await getDocs(formsQuery);
      
      if (formsSnapshot.empty) {
        setError("No forms found that require approval.");
        addOperationDetail("❌ No forms found that require approval.");
        setLoading(false);
        return;
      }

      const formIds = formsSnapshot.docs.map(doc => doc.id);
      stats.formsProcessed = formIds.length;
      
      addOperationDetail(`✅ Found ${formIds.length} forms with requiresApproval=true.`);

      // 2. Process each form's submissions in batches
      for (let i = 0; i < formIds.length; i++) {
        const formId = formIds[i];
        await processFormSubmissions(formId, stats);
        
        // Update progress
        setProgress(((i + 1) / formIds.length) * 100);
      }

      setResults(stats);
      addOperationDetail(`🎉 Update completed successfully!`);
    } catch (err) {
      console.error("Error updating approval data:", err);
      setError("Failed to update approval data. See console for details.");
      addOperationDetail(`❌ Error: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setLoading(false);
      setConfirmOpen(false);
    }
  };

  const deepScanSubmissions = async () => {
    setLoading(true);
    setError(null);
    setResults(null);
    setOperationDetails([]);
    setProgress(0);

    try {
      const stats = {
        formsProcessed: 0,
        submissionsScanned: 0,
        submissionsFixed: 0,
        errors: 0
      };

      addOperationDetail("🔍 Starting deep scan for submissions with inconsistent approval data...");

      // 1. Find all forms that require approval
      const formsQuery = query(
        collection(db, "forms"),
        where("requiresApproval", "==", true)
      );
      
      const formsSnapshot = await getDocs(formsQuery);
      
      if (formsSnapshot.empty) {
        setError("No forms found that require approval.");
        addOperationDetail("❌ No forms found that require approval.");
        setLoading(false);
        return;
      }

      const formIds = formsSnapshot.docs.map(doc => doc.id);
      stats.formsProcessed = formIds.length;
      
      addOperationDetail(`✅ Found ${formIds.length} forms with requiresApproval=true.`);

      // 2. For each form, get all submissions (regardless of approval status)
      let totalFixed = 0;
      let totalScanned = 0;

      for (let i = 0; i < formIds.length; i++) {
        const formId = formIds[i];
        
        // Get form info for better logging
        const formDoc = await getDoc(doc(db, "forms", formId));
        const formTitle = formDoc.exists() ? formDoc.data().formTitle || 'Untitled Form' : formId;
        
        addOperationDetail(`📊 Scanning all submissions for form "${formTitle}" (${formId})...`);
        
        // Get all submissions for this form
        const submissionsQuery = query(
          collection(db, "form_submissions"),
          where("formId", "==", formId)
        );
        
        const submissionsSnapshot = await getDocs(submissionsQuery);
        const totalSubmissions = submissionsSnapshot.size;
        totalScanned += totalSubmissions;
        
        addOperationDetail(`📋 Found ${totalSubmissions} total submissions for form "${formTitle}"`);
        
        // Check each submission
        let fixedCount = 0;
        const batchSize = 450; // Firestore limit is 500
        let batches = [];
        let batch = writeBatch(db);
        let batchItemCount = 0;
        
        for (const submissionDoc of submissionsSnapshot.docs) {
          const submissionData = submissionDoc.data();
          const submissionId = submissionDoc.id;
          
          // Check if approval fields are missing or inconsistent
          const needsUpdate = 
            submissionData.approvalRequired !== true || 
            !submissionData.approvalStatus ||
            !submissionData.hasOwnProperty('approvalComments');
          
          if (needsUpdate) {
            // Prepare update data
            const updateData: Record<string, any> = {};
            
            if (submissionData.approvalRequired !== true) {
              updateData.approvalRequired = true;
            }
            
            if (!submissionData.approvalStatus) {
              updateData.approvalStatus = 'pending';
            }
            
            if (!submissionData.hasOwnProperty('approvalComments')) {
              updateData.approvalComments = '';
            }
            
            // Update the submission
            batch.update(doc(db, "form_submissions", submissionId), updateData);
            batchItemCount++;
            
            // Also check and update submission details
            const detailsRef = doc(db, "submission_details", submissionId);
            try {
              const detailsDoc = await getDoc(detailsRef);
              if (detailsDoc.exists()) {
                batch.update(detailsRef, updateData);
                batchItemCount++;
              }
            } catch (detailsError) {
              console.error(`Error checking details for ${submissionId}:`, detailsError);
            }
            
            // If we're approaching the batch limit, create a new batch
            if (batchItemCount >= batchSize) {
              batches.push(batch);
              batch = writeBatch(db);
              batchItemCount = 0;
            }
            
            fixedCount++;
            totalFixed++;
          }
        }
        
        // Add the final batch if it has items
        if (batchItemCount > 0) {
          batches.push(batch);
        }
        
        // Commit all batches
        if (batches.length > 0) {
          addOperationDetail(`🔄 Committing ${batches.length} batches for form "${formTitle}"...`);
          
          for (let j = 0; j < batches.length; j++) {
            try {
              await batches[j].commit();
              addOperationDetail(`✓ Committed batch ${j+1}/${batches.length} for form "${formTitle}"`);
            } catch (batchError) {
              console.error(`Error committing batch ${j+1}:`, batchError);
              addOperationDetail(`❌ Error committing batch ${j+1}: ${batchError}`);
              stats.errors++;
            }
          }
        }
        
        addOperationDetail(`✅ Fixed ${fixedCount} of ${totalSubmissions} submissions for form "${formTitle}"`);
        
        // Update progress
        setProgress(((i + 1) / formIds.length) * 100);
      }
      
      stats.submissionsScanned = totalScanned;
      stats.submissionsFixed = totalFixed;
      
      setResults({
        formsProcessed: stats.formsProcessed,
        submissionsUpdated: stats.submissionsFixed,
        submissionsScanned: stats.submissionsScanned,
        errors: stats.errors
      });
      
      addOperationDetail(`🎉 Deep scan completed! Fixed ${totalFixed} submissions across ${formIds.length} forms.`);
    } catch (err) {
      console.error("Error in deep scan:", err);
      setError("Failed to complete deep scan. See console for details.");
      addOperationDetail(`❌ Error: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setLoading(false);
      setConfirmOpen(false);
    }
  };

  const handleCheckSpecificSubmission = async () => {
    if (!specificSubmissionId.trim()) {
      setError("Please enter a submission ID");
      return;
    }
    
    setLoading(true);
    setError(null);
    setResults(null);
    setOperationDetails([]);
    setProgress(0);
    
    try {
      addOperationDetail(`🔍 Checking submission with ID: ${specificSubmissionId}`);
      
      const submissionInfo = await checkSpecificSubmission(specificSubmissionId);
      
      if (!submissionInfo.exists) {
        addOperationDetail(`❌ Submission with ID ${specificSubmissionId} does not exist`);
        setError(`Submission with ID ${specificSubmissionId} does not exist`);
        setLoading(false);
        return;
      }
      
      addOperationDetail(`✅ Found submission with ID: ${specificSubmissionId}`);
      addOperationDetail(`📋 Submission details:`);
      addOperationDetail(`   Form ID: ${submissionInfo.formId || 'Missing'}`);
      addOperationDetail(`   Form Title: ${submissionInfo.formTitle || 'Unknown'}`);
      addOperationDetail(`   Email: ${submissionInfo.email || 'Missing'}`);
      addOperationDetail(`   Approval Status: ${submissionInfo.approvalStatus || 'Missing'}`);
      addOperationDetail(`   Approval Required: ${submissionInfo.approvalRequired || 'Missing'}`);
      addOperationDetail(`   Archived: ${submissionInfo.archived === null ? 'Missing' : submissionInfo.archived}`);
      
      const date = submissionInfo.submittedAt?.toDate 
        ? submissionInfo.submittedAt.toDate().toISOString() 
        : 'Unknown date';
      addOperationDetail(`   Submitted At: ${date}`);
      
      addOperationDetail(`   In form_submissions collection: ${submissionInfo.inFormSubmissions ? 'Yes' : 'No'}`);
      addOperationDetail(`   In submission_details collection: ${submissionInfo.inSubmissionDetails ? 'Yes' : 'No'}`);
      addOperationDetail(`   Form exists: ${submissionInfo.formExists ? 'Yes' : 'No'}`);
      
      if (submissionInfo.issues.length > 0) {
        addOperationDetail(`⚠️ Issues found:`);
        submissionInfo.issues.forEach(issue => {
          addOperationDetail(`   - ${issue}`);
        });
        
        setResults({
          formsProcessed: 0,
          submissionsUpdated: 0,
          submissionsScanned: 1,
          errors: submissionInfo.issues.length
        });
      } else {
        addOperationDetail(`✅ No issues found with this submission`);
        setResults({
          formsProcessed: 0,
          submissionsUpdated: 0,
          submissionsScanned: 1,
          errors: 0
        });
      }
    } catch (error) {
      console.error(`Error checking submission ${specificSubmissionId}:`, error);
      setError(`Error checking submission: ${error instanceof Error ? error.message : String(error)}`);
      addOperationDetail(`❌ Error: ${error instanceof Error ? error.message : String(error)}`);
    } finally {
      setLoading(false);
      setProgress(100);
      setShowDetails(true);
    }
  };

  const handleFixSpecificSubmission = async () => {
    if (!specificSubmissionId.trim()) {
      setError("Please enter a submission ID");
      return;
    }
    
    setLoading(true);
    setError(null);
    setProgress(0);
    
    try {
      addOperationDetail(`🔧 Fixing submission with ID: ${specificSubmissionId}`);
      
      const result = await fixSpecificSubmission(specificSubmissionId);
      
      if (result.success) {
        addOperationDetail(`✅ ${result.message}`);
        
        if (Object.keys(result.updatedFields).length > 0) {
          addOperationDetail(`📝 Updated fields:`);
          
          for (const [collection, fields] of Object.entries(result.updatedFields)) {
            addOperationDetail(`   In ${collection}:`);
            for (const [key, value] of Object.entries(fields as Record<string, any>)) {
              addOperationDetail(`     - ${key}: ${value}`);
            }
          }
        } else {
          addOperationDetail(`ℹ️ No fields needed updating`);
        }
        
        setResults({
          formsProcessed: 0,
          submissionsUpdated: 1,
          submissionsScanned: 1,
          errors: 0
        });
      } else {
        addOperationDetail(`❌ ${result.message}`);
        setError(result.message);
        setResults({
          formsProcessed: 0,
          submissionsUpdated: 0,
          submissionsScanned: 1,
          errors: 1
        });
      }
    } catch (error) {
      console.error(`Error fixing submission ${specificSubmissionId}:`, error);
      setError(`Error fixing submission: ${error instanceof Error ? error.message : String(error)}`);
      addOperationDetail(`❌ Error: ${error instanceof Error ? error.message : String(error)}`);
    } finally {
      setLoading(false);
      setProgress(100);
      setShowDetails(true);
    }
  };

  const handleCheckForm = async () => {
    if (!specificFormId.trim()) {
      setError("Please enter a form ID");
      return;
    }
    
    setLoading(true);
    setError(null);
    setResults(null);
    setOperationDetails([]);
    setProgress(0);
    
    try {
      addOperationDetail(`🔍 Checking submissions for form: ${specificFormId}`);
      
      const formCheck = await checkAllSubmissionsForForm(specificFormId);
      
      addOperationDetail(`📊 Submission analysis for form ${specificFormId}:`);
      addOperationDetail(`✓ Found ${formCheck.inSubmissionDetails.length} submissions in submission_details collection`);
      addOperationDetail(`✓ Found ${formCheck.inFormSubmissions.length} submissions in form_submissions collection`);
      
      if (formCheck.mismatches.length === 0) {
        addOperationDetail(`✅ No mismatches found between collections`);
      } else {
        addOperationDetail(`⚠️ Found ${formCheck.mismatches.length} issues:`);
        
        formCheck.mismatches.forEach((mismatch, index) => {
          if (index < 10) { // Show only first 10 for brevity
            addOperationDetail(`   - ${mismatch.type} for submission ${mismatch.id || mismatch.submission.id}`);
            
            if (mismatch.type === "Status mismatches" && mismatch.mismatches) {
              mismatch.mismatches.forEach((m: string) => {
                addOperationDetail(`     • ${m}`);
              });
            }
          } else if (index === 10) {
            addOperationDetail(`   - ... and ${formCheck.mismatches.length - 10} more issues`);
          }
        });
      }
      
      // List pending submissions in detail
      const pendingInDetails = formCheck.inSubmissionDetails.filter(s => 
        s.approvalStatus === "pending" && s.approvalRequired === true && s.archived !== true
      );
      
      if (pendingInDetails.length > 0) {
        addOperationDetail(`\n📋 Found ${pendingInDetails.length} pending submissions in submission_details:`);
        pendingInDetails.forEach((submission, index) => {
          if (index < 10) {
            addOperationDetail(`   ${index + 1}. ID: ${submission.id}`);
            addOperationDetail(`      Email: ${submission.email || 'Unknown'}`);
            addOperationDetail(`      Status: ${submission.approvalStatus}`);
            addOperationDetail(`      Required: ${submission.approvalRequired}`);
            addOperationDetail(`      Archived: ${submission.archived}`);
            
            const date = submission.submittedAt?.toDate ? 
              submission.submittedAt.toDate().toISOString() : 'Unknown date';
            addOperationDetail(`      Submitted: ${date}`);
          } else if (index === 10) {
            addOperationDetail(`   ... and ${pendingInDetails.length - 10} more pending submissions`);
          }
        });
      } else {
        addOperationDetail(`❓ No pending submissions found in submission_details collection`);
      }
      
      // Check pending in form_submissions too
      const pendingInForm = formCheck.inFormSubmissions.filter(s => 
        s.approvalStatus === "pending" && s.approvalRequired === true && s.archived !== true
      );
      
      if (pendingInForm.length > 0 && pendingInForm.length !== pendingInDetails.length) {
        addOperationDetail(`\n⚠️ Found ${pendingInForm.length} pending submissions in form_submissions, but ${pendingInDetails.length} in submission_details!`);
      }
      
      setResults({
        formsProcessed: 1,
        submissionsUpdated: 0,
        submissionsScanned: formCheck.inSubmissionDetails.length + formCheck.inFormSubmissions.length,
        errors: formCheck.mismatches.length
      });
      
    } catch (error) {
      console.error(`Error checking form ${specificFormId}:`, error);
      setError(`Error checking form: ${error instanceof Error ? error.message : String(error)}`);
      addOperationDetail(`❌ Error: ${error instanceof Error ? error.message : String(error)}`);
    } finally {
      setLoading(false);
      setProgress(100);
      setShowDetails(true);
    }
  };

  const handleSyncForm = async () => {
    if (!specificFormId.trim()) {
      setError("Please enter a form ID");
      return;
    }
    
    setLoading(true);
    setError(null);
    setResults(null);
    setOperationDetails([]);
    setProgress(0);
    
    try {
      addOperationDetail(`🔄 Syncing submissions for form: ${specificFormId}`);
      
      const syncResult = await syncSubmissionCollections(specificFormId);
      
      addOperationDetail(`✅ Sync completed successfully:`);
      addOperationDetail(`   - Created ${syncResult.created} missing submissions in submission_details`);
      addOperationDetail(`   - Updated ${syncResult.updated} submissions with mismatched approval status`);
      
      if (syncResult.errors > 0) {
        addOperationDetail(`⚠️ Encountered ${syncResult.errors} errors during sync`);
      }
      
      setResults({
        formsProcessed: 1,
        submissionsUpdated: syncResult.updated + syncResult.created,
        submissionsScanned: syncResult.updated + syncResult.created + syncResult.errors,
        errors: syncResult.errors
      });
      
      // Check again after sync to verify the results
      addOperationDetail(`\n🔍 Verifying sync results...`);
      
      const formCheckAfter = await checkAllSubmissionsForForm(specificFormId);
      
      if (formCheckAfter.mismatches.length === 0) {
        addOperationDetail(`✅ Verification successful: No mismatches found after sync`);
      } else {
        addOperationDetail(`⚠️ Verification found ${formCheckAfter.mismatches.length} remaining issues`);
      }
      
    } catch (error) {
      console.error(`Error syncing form ${specificFormId}:`, error);
      setError(`Error syncing form: ${error instanceof Error ? error.message : String(error)}`);
      addOperationDetail(`❌ Error: ${error instanceof Error ? error.message : String(error)}`);
    } finally {
      setLoading(false);
      setProgress(100);
      setShowDetails(true);
    }
  };

  // Process submissions for a single form
  const processFormSubmissions = async (formId: string, stats: any) => {
    try {
      // Get form data to include title in logs
      const formRef = doc(db, "forms", formId);
      const formSnap = await getDoc(formRef);
      const formTitle = formSnap.exists() ? formSnap.data().formTitle || 'Untitled Form' : formId;
      
      // Find submissions for this form
      const submissionsQuery = query(
        collection(db, "form_submissions"),
        where("formId", "==", formId),
        where("archived", "==", false) // Only update non-archived submissions
      );
      
      const submissionsSnapshot = await getDocs(submissionsQuery);
      
      if (submissionsSnapshot.empty) {
        addOperationDetail(`ℹ️ No submissions found for form "${formTitle}" (${formId})`);
        return;
      }
      
      addOperationDetail(`📋 Found ${submissionsSnapshot.docs.length} submissions for form "${formTitle}" (${formId})`);
      
      // Get submissions that need updating
      const submissionsToUpdate = submissionsSnapshot.docs.filter(doc => {
        const data = doc.data();
        // Check if any approval fields are missing or have incorrect values
        return (
          data.approvalRequired !== true ||
          !data.approvalStatus ||
          !data.hasOwnProperty('approvalComments')
        );
      });
      
      addOperationDetail(`🔄 ${submissionsToUpdate.length} submissions need updating for form "${formTitle}"`);
      
      if (submissionsToUpdate.length === 0) return;
      
      // Process in batches of 450 to stay well under Firestore's 500 limit
      const BATCH_SIZE = 450;
      for (let i = 0; i < submissionsToUpdate.length; i += BATCH_SIZE) {
        const batch = writeBatch(db);
        const batchItems = submissionsToUpdate.slice(i, i + BATCH_SIZE);
        
        batchItems.forEach(submission => {
          const data = submission.data();
          
          // Prepare the update data - only include fields that need updating
          const updateData: Record<string, any> = {};
          
          // Set approvalRequired to true
          if (data.approvalRequired !== true) {
            updateData.approvalRequired = true;
          }
          
          // If no approvalStatus exists, set it to 'pending'
          if (!data.approvalStatus) {
            updateData.approvalStatus = 'pending';
          }
          
          // If approvalComments is missing, add an empty string
          if (!data.hasOwnProperty('approvalComments')) {
            updateData.approvalComments = '';
          }
          
          // Skip if no updates needed (shouldn't happen given our filter, but just to be safe)
          if (Object.keys(updateData).length === 0) return;
          
          // Update the main submission document
          batch.update(doc(db, "form_submissions", submission.id), updateData);
          
          // Also update corresponding details document if it exists
          getDoc(doc(db, "submission_details", submission.id))
            .then(detailsDoc => {
              if (detailsDoc.exists()) {
                const detailsBatch = writeBatch(db);
                detailsBatch.update(doc(db, "submission_details", submission.id), updateData);
                detailsBatch.commit()
                  .catch(err => console.error(`Error updating details for submission ${submission.id}:`, err));
              }
            })
            .catch(err => console.error(`Error checking details for submission ${submission.id}:`, err));
        });
        
        // Commit the batch
        await batch.commit();
        stats.submissionsUpdated += batchItems.length;
        
        addOperationDetail(`✓ Updated ${batchItems.length} submissions in batch ${Math.floor(i/BATCH_SIZE) + 1} for form "${formTitle}"`);
      }
      
    } catch (error) {
      addOperationDetail(`❌ Error processing form ${formId}: ${error}`);
      stats.errors++;
      console.error(`Error processing form ${formId}:`, error);
    }
  };

  const handleFixArchivedField = async () => {
    setLoading(true);
    setError(null);
    setResults(null);
    setOperationDetails([]);
    setProgress(0);

    try {
      addOperationDetail("🔍 Starting scan for submissions with missing 'archived' field...");
      
      const stats = await fixMissingArchivedField((detail) => {
        addOperationDetail(detail);
        // Update progress based on the current step in the operation
        if (detail.includes('%')) {
          const percentMatch = detail.match(/(\d+)%/);
          if (percentMatch && percentMatch[1]) {
            setProgress(parseInt(percentMatch[1]));
          }
        }
      });
      
      setResults({
        formsProcessed: stats.formsProcessed,
        submissionsUpdated: stats.submissionsFixed,
        submissionsScanned: stats.submissionsScanned,
        errors: stats.errors
      });
      
      addOperationDetail(`🎉 'Archived' field fix completed successfully!`);
      addOperationDetail(`✅ Fixed ${stats.submissionsFixed} submissions across ${stats.formsProcessed} forms.`);

    } catch (err) {
      console.error("Error fixing archived field:", err);
      setError("Failed to fix archived field. See console for details.");
      addOperationDetail(`❌ Error: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setLoading(false);
      setProgress(100);
      setShowDetails(true);
    }
  };

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: { xs: 2, sm: 3 }, 
        mb: { xs: 2, sm: 3 }, 
        borderRadius: 2,
        border: '1px dashed',
        borderColor: 'warning.main',
        bgcolor: 'rgba(255, 152, 0, 0.05)'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <AdminPanelSettings color="warning" />
        <Typography variant="h6">Administrator Tools</Typography>
        <Chip 
          label="Maintenance" 
          color="warning" 
          size="small" 
          sx={{ height: 20, fontSize: '0.7rem' }} 
        />
      </Box>
      
      <Typography variant="subtitle1" gutterBottom>
        Fix Legacy Approval Data
      </Typography>
      
      <Typography variant="body2" color="text.secondary" paragraph>
        This tool ensures all submissions from forms with approval required have the necessary approval fields.
        It will add <code>approvalRequired: true</code>, <code>approvalStatus: "pending"</code>, and 
        <code>approvalComments: ""</code> to any submissions that are missing these fields.
      </Typography>
      
      <Box sx={{ mt: 2, mb: 3, borderTop: '1px dashed', borderColor: 'divider', pt: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Check or Fix Specific Submission
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
          <TextField
            label="Submission ID"
            variant="outlined"
            size="small"
            value={specificSubmissionId}
            onChange={(e) => setSpecificSubmissionId(e.target.value)}
            sx={{ flexGrow: 1, maxWidth: 400 }}
            placeholder="Example: PTUFkTh1Ou3GYALtnUPb"
          />
          
          <Button
            variant="outlined"
            color="info"
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SearchIcon />}
            disabled={loading || !specificSubmissionId.trim()}
            onClick={handleCheckSpecificSubmission}
          >
            Check Submission
          </Button>
          
          <Button
            variant="contained"
            color="warning"
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Build />}
            disabled={loading || !specificSubmissionId.trim()}
            onClick={handleFixSpecificSubmission}
          >
            Fix Submission
          </Button>
        </Box>
      </Box>

      <Box sx={{ mt: 2, mb: 3, borderTop: '1px dashed', borderColor: 'divider', pt: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Check or Fix Form Submissions
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
          <TextField
            label="Form ID"
            variant="outlined"
            size="small"
            value={specificFormId}
            onChange={(e) => setSpecificFormId(e.target.value)}
            sx={{ flexGrow: 1, maxWidth: 400 }}
            placeholder="Example: e1baa0c6-333e-4985-afdc-1746e35e0e0f"
          />
          
          <Button
            variant="outlined"
            color="info"
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SearchIcon />}
            disabled={loading || !specificFormId.trim()}
            onClick={handleCheckForm}
          >
            Check Form
          </Button>
          
          <Button
            variant="contained"
            color="warning"
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SyncIcon />}
            disabled={loading || !specificFormId.trim()}
            onClick={handleSyncForm}
          >
            Sync Form Collections
          </Button>
        </Box>
      </Box>

      <Button
        variant="contained"
        color="warning"
        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Build />}
        disabled={loading}
        onClick={() => {
          setConfirmOpen(true);
          setConfirmAction(() => fixApprovalData);
        }}
        sx={{ mt: 1, mb: loading ? 2 : 0 }}
      >
        {loading ? 'Processing...' : 'Fix Approval Data'}
      </Button>

      <Button
        variant="outlined"
        color="warning"
        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <BuildCircle />}
        disabled={loading}
        onClick={() => {
          setConfirmOpen(true);
          setConfirmAction(() => deepScanSubmissions);
        }}
        sx={{ mt: 1, mb: loading ? 2 : 0, ml: 2 }}
      >
        {loading ? 'Processing...' : 'Deep Scan Submissions'}
      </Button>

      <Button
        variant="outlined"
        color="secondary"
        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <ArchiveIcon />}
        disabled={loading}
        onClick={handleFixArchivedField}
        sx={{ mt: 1, mb: loading ? 2 : 0, ml: 2 }}
      >
        {loading ? 'Processing...' : 'Fix Archived Field'}
      </Button>
      
      {loading && (
        <Box sx={{ mt: 2, mb: 1 }}>
          <LinearProgress variant="determinate" value={progress} color="warning" />
          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
            Processing... {Math.round(progress)}% complete
          </Typography>
        </Box>
      )}
      
      {error && (
        <Alert severity="error" onClose={() => setError(null)} sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      
      {results && (
        <Alert severity="success" sx={{ mt: 2 }}>
          <Typography variant="body2">
            <strong>Update complete!</strong>
          </Typography>
          <Box component="ul" sx={{ mt: 1, mb: 0.5, pl: 2 }}>
            <li>Forms processed: {results.formsProcessed}</li>
            <li>Submissions updated: {results.submissionsUpdated}</li>
            {results.submissionsScanned !== undefined && (
              <li>Submissions scanned: {results.submissionsScanned}</li>
            )}
            {results.errors > 0 && (
              <li style={{ color: '#d32f2f' }}>Errors: {results.errors}</li>
            )}
          </Box>
          <Button 
            size="small"
            variant="outlined" 
            color="inherit"
            onClick={() => setShowDetails(!showDetails)}
            sx={{ mt: 1 }}
          >
            {showDetails ? "Hide Details" : "Show Details"}
          </Button>
        </Alert>
      )}
      
      {showDetails && operationDetails.length > 0 && (
        <Paper 
          variant="outlined" 
          sx={{ 
            mt: 2, 
            p: 2, 
            maxHeight: 300, 
            overflow: 'auto',
            bgcolor: 'background.default',
            fontFamily: 'monospace',
            fontSize: '0.8rem'
          }}
        >
          {operationDetails.map((detail, index) => (
            <Box key={index} sx={{ mb: 0.5 }}>{detail}</Box>
          ))}
        </Paper>
      )}
      
      {/* Confirmation Dialog */}
      <Dialog open={confirmOpen} onClose={() => !loading && setConfirmOpen(false)}>
        <DialogTitle sx={{ bgcolor: 'warning.main', color: 'warning.contrastText' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <BuildCircle sx={{ mr: 1 }} />
            Confirm Database Update
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText>
            <Typography paragraph>
              This will update <strong>all</strong> submissions from forms with approval required to have the following fields:
            </Typography>
            
            <Box component="ul" sx={{ pl: 4 }}>
              <li><code>approvalRequired: true</code></li>
              <li><code>approvalStatus: "pending"</code> (if missing)</li>
              <li><code>approvalComments: ""</code> (if missing)</li>
            </Box>
            
            <Typography paragraph sx={{ mt: 2, fontWeight: 'bold' }}>
              This operation only affects submissions from forms with approval required setting enabled.
            </Typography>
            
            <Typography sx={{ color: 'warning.dark', mt: 1 }}>
              This maintenance operation affects your database directly.
              Are you sure you want to continue?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setConfirmOpen(false)} 
            color="inherit"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button 
            onClick={confirmAction || (() => {})} 
            color="warning"
            variant="contained"
            disabled={loading}
            endIcon={loading ? <CircularProgress size={16} color="inherit" /> : <Build />}
          >
            {loading ? 'Processing...' : 'Yes, Fix Approval Data'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default FixApprovalData;

async function checkSubmissionCollections() {
  // Get document counts for different collections
  const form_submissions = (await getDocs(collection(db, "form_submissions"))).size;
  const formSubmissions = (await getDocs(collection(db, "formSubmissions"))).size;
  const submission_details = (await getDocs(collection(db, "submission_details"))).size;
  
  // Determine if there are mismatched collections
  const mismatchedCollections = (form_submissions > 0 && formSubmissions > 0) || 
    (submission_details > 0 && form_submissions !== submission_details);
  
  return {
    form_submissions,
    formSubmissions,
    submission_details,
    mismatchedCollections
  };
}

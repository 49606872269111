import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebase";
import { doc, setDoc, getDoc, updateDoc, addDoc, collection, serverTimestamp } from "firebase/firestore"; // Add necessary imports
import { sanitizeForFirebase } from "../utils/firebase-helpers"; // Add this import

// Update the QuestionType type and Question interface

export type QuestionType = 
  | "text"
  | "number"
  | "select"
  | "multi-select"
  | "checkbox"
  | "image"
  | "file"    // Add this line
  | "date"
  | "date-range"  // Add this
  | "signature"
  | "repeatable"
  | "long-text"
  | "instructions"
  | "section"
  | "time";  // Add this line

// Define the SubField interface
export interface SubField {
  id: string;
  type: string;
  label: string;
  required: boolean;
  options?: string[];
}

// Update the Question interface to support multiple follow-ups

export interface Question {
  timeMode: string;
  instructionRows: number;
  fullWidth: boolean;
  id: string;
  type: string;
  label: string;
  required?: boolean;
  options?: string[];
  
  // Add this new property
  selectionMode?: "multiple" | "single"; // For multi-checkbox questions
  
  // Rest of your existing properties
  checkboxStyle?: "single" | "yes-no" | "multi";
  checkboxLabel?: string;
  placeholder?: string;
  conditionalFollowUps?: {
    [key: string]: {
      question: string;
      required: boolean;
    }
  };
  followUp?: {
    question: string;
    required: boolean;
  };
  allowComment?: boolean;
  commentLabel?: string; 
  commentRequired?: boolean;
  subFields?: SubField[];
  
  // Checkbox fields
  allowOther?: boolean; // Add new multi-checkbox related properties
  
  // Add this new field for multi-select follow-ups
  followUps?: Array<{
    triggerOption: string;
    question: string;
    required: boolean;
  }>;
  
  // The newer conditional follow-ups structure
  
  // Long text fields
  minChars?: number;
  maxChars?: number;
  helperText?: string;
  
  // Instruction fields
  instructionStyle?: "standard" | "note" | "warning" | "tip";
  instructionText?: string;
  
  // Repeatable fields
  minEntries?: number;
  maxEntries?: number;

  // Date Range fields
  startLabel?: string;
  endLabel?: string;
  enableHours?: boolean;
  hoursLabel?: string;
  includeWeekends?: boolean;
  minDays?: number | null;
  maxDays?: number | null;

  // File fields
  acceptedFileTypes?: string;
  uploadButtonText?: string;

  // Section question properties
  description?: string;
  sectionQuestions?: Question[];
  sectionStyle?: string;
  sectionBgColor?: string;
  sectionBorderColor?: string;
  collapsible?: boolean;
  initiallyExpanded?: boolean;
  showSectionNumbers?: boolean;
  order?: number; // Add order property
  conditionalDisplay?: {
    questionId: string;
    operator: string;
    value: string | boolean;
  };
  defaultValue?: any;
  yesFollowUp?: any;
  noFollowUp?: any;
  [key: string]: any; // Allow additional properties for backward compatibility
}

// Update the SaveFormParams interface to include extraData
interface SaveFormParams {
  email: string;
  extraData?: Record<string, any>; // For admin assignment data
}

// Update the FormState interface
interface FormState {
  formTitle: string;
  formDescription: string;
  formSlug: string;
  setFormTitle: (title: string) => void;
  setFormDescription: (description: string) => void;
  setFormSlug: (slug: string) => void;
  questions: Question[];
  setQuestions: (questions: Question[]) => void;
  addQuestion: (type: QuestionType) => void;
  updateQuestion: (id: string, updates: Partial<Question>) => void;
  removeQuestion: (id: string) => void;
  saveForm: (params: SaveFormParams) => Promise<string>;
  loadForm: (formId: string) => Promise<void>;
  formId: string | null;
  setFormId: (id: string) => void;
  email: string;
  setEmail: (email: string) => void;
  companyLogo: string | null;
  setCompanyLogo: (logo: string | null) => void;
  userId: string | null; // Add userId to the state
  setUserId: (id: string) => void; // Add setUserId to the state
  allowPdfCopy: boolean; // Add this new field
  setAllowPdfCopy: (allowPdfCopy: boolean) => void; // Add this new setter
  requiresApproval: boolean;  // New property
  setRequiresApproval: (requiresApproval: boolean) => void;  // New setter
}

export const useFormStore = create<FormState>((set, get) => ({
  formTitle: "",
  formDescription: "", 
  formSlug: "", // Add this
  setFormTitle: (title) => set({ formTitle: title }),
  setFormDescription: (description) => set({ formDescription: description }),
  setFormSlug: (slug) => set({ formSlug: slug }), // Add this

  questions: [],
  setQuestions: (questions) => set({ questions }),

  addQuestion: (type) =>
    set((state) => {
      // Create a properly initialized question based on type
      const newQuestion: Question = {
        id: uuidv4(),
        label: getDefaultLabelForType(type), // Add a helper function to get default labels
        type,
        options: type === "select" || type === "multi-select" ? ["Option 1", "Option 2"] : [],
        required: false,
        order: state.questions.length,
        instructionRows: type === "instructions" ? 2 : 0,
        fullWidth: false,
        timeMode: type === "time" ? "single" : "" // Change this line - default to "single"
      };
      
      // Add type-specific default properties
      if (type === "repeatable") {
        newQuestion.subFields = [{
          id: `sub_${Date.now()}`,
          type: "text",
          label: "Field 1",
          required: false
        }];
      } else if (type === "instructions") {
        newQuestion.instructionStyle = "standard";
      } else if (type === "file") {
        newQuestion.uploadButtonText = "Select a file";
        newQuestion.acceptedFileTypes = "";
        newQuestion.helperText = "Maximum file size: 5MB";
      } else if (type === "section") {
        newQuestion.description = "";
        newQuestion.sectionQuestions = [];
        newQuestion.sectionStyle = "default";
        newQuestion.sectionBgColor = "transparent";
        newQuestion.collapsible = false;
        newQuestion.initiallyExpanded = true;
        newQuestion.showSectionNumbers = false;
      } else if (type === "text" || type === "long-text") {
        newQuestion.helperText = type === "text" ? "Enter your answer here" : "Please provide a detailed response";
        newQuestion.minChars = 0;
        newQuestion.maxChars = 0;
      } else if (type === "time") {
        newQuestion.timeMode = "single"; // Explicitly set to "single" mode
      }
      
      return {
        questions: [...state.questions, newQuestion],
      };
    }),

  updateQuestion: (questionId, updates) => {
    set((state) => {
      const updatedQuestions = state.questions.map((q) =>
        q.id === questionId ? { ...q, ...updates } : q
      );
      return { questions: updatedQuestions };
    });
  },

  removeQuestion: (id) =>
    set((state) => ({
      questions: state.questions.filter((q) => q.id !== id),
    })),

  formId: null,
  setFormId: (id) => set({ formId: id }),

  email: "",
  setEmail: (email) => set({ email }),

  companyLogo: null,
  setCompanyLogo: (logo) => set({ companyLogo: logo }),

  userId: null, // Initialize userId
  setUserId: (id) => set({ userId: id }), // Initialize setUserId

  allowPdfCopy: false, // Default to false (more restrictive for GDPR compliance)
  setAllowPdfCopy: (allowPdfCopy: boolean) => set({ allowPdfCopy }),

  requiresApproval: false, // New property with default false
  setRequiresApproval: (requiresApproval: boolean) => set({ requiresApproval }), // New setter

  saveForm: async ({ email, extraData = {} }: SaveFormParams) => {
    const { formTitle, formDescription, formSlug, questions, formId, userId, allowPdfCopy, requiresApproval } = get();
    
    if (!formTitle) {
      throw new Error("Form title is required");
    }
    
    if (!userId) {
      throw new Error("User ID is required to save a form");
    }
    
    // Sanitize questions to ensure all have proper values
    const sanitizedQuestions = questions.map(q => {
      // Start with a base question with essential fields
      const baseQuestion: Question = {
        id: q.id || uuidv4(),
        type: q.type || "text",
        label: typeof q.label === 'string' ? q.label : getDefaultLabelForType(q.type || "text"),
        required: Boolean(q.required),
        options: Array.isArray(q.options) ? q.options : [],
        // Don't override timeMode if it already exists
        timeMode: q.timeMode || (q.type === "time" ? "single" : ""),
        instructionRows: q.type === "instructions" ? (q.instructionRows || 2) : 0,
        fullWidth: Boolean(q.fullWidth)
      };
      
      // Preserve the order if it exists
      if (typeof q.order === 'number') {
        baseQuestion.order = q.order;
      } else {
        baseQuestion.order = questions.findIndex(item => item.id === q.id);
      }
      
      // Add all other properties from the original question
      // This preserves any other custom properties for backward compatibility
      const result = { ...q };
      
      // Apply the base properties, but don't override existing valid values
      Object.entries(baseQuestion).forEach(([key, value]) => {
        // Only set the base value if the current value is undefined/null/empty string
        if (result[key] === undefined || result[key] === null || result[key] === '') {
          result[key] = value;
        }
      });
      
      return result;
    });
    
    try {
      // Create the document data
      const formData: any = {
        formTitle,
        formDescription,
        formSlug: formSlug || '', // Add the slug
        questions: sanitizedQuestions,
        email: email || get().email,
        userId,
        allowPdfCopy,
        requiresApproval, // Include in saved data
        ...extraData,
        updatedAt: new Date()
      };
      
      // For new forms, add createdAt
      if (!formId) {
        formData.createdAt = new Date();
      }
      
      // Apply the Firebase sanitization before saving
      const sanitizedFormData = sanitizeForFirebase(formData);
      
      // Save to Firestore
      let savedFormId = formId;
      
      if (formId) {
        // Update existing form
        const formRef = doc(db, "forms", formId);
        await setDoc(formRef, sanitizedFormData, { merge: true });
        console.log("Form updated with ID:", formId);
      } else {
        // Create new form
        const formCollectionRef = collection(db, "forms");
        const newFormRef = await addDoc(formCollectionRef, sanitizedFormData);
        savedFormId = newFormRef.id;
        console.log("New form created with ID:", savedFormId);
        
        // Store creation time in session storage
        sessionStorage.setItem(`form_created_${savedFormId}`, new Date().toString());
        
        // Update formId in state
        set({ formId: savedFormId });
      }
      
      // Return the form ID (ensure it's always a string)
      return savedFormId!;
    } catch (error) {
      console.error("Error saving form:", error);
      throw error;
    }
  },

  loadForm: async (formId) => {
    try {
      const docRef = doc(db, "forms", formId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const formData = docSnap.data();
        console.log("Loaded Form:", formData);

        set({
          formId,
          formTitle: formData.formTitle || "Untitled Form",
          formDescription: formData.formDescription || "",
          formSlug: formData.formSlug || "", // Add this
          questions: formData.questions || [],
          email: formData.email || "",
          companyLogo: formData.companyLogo || null, // This will now be base64
          allowPdfCopy: formData.allowPdfCopy !== undefined ? formData.allowPdfCopy : false,
          requiresApproval: formData.requiresApproval === true, // Load from data
        });
      } else {
        console.error("Form not found");
      }
    } catch (error) {
      console.error("Error loading form:", error);
    }
  },
}));

// Add this helper function at the bottom of the file
function getDefaultLabelForType(type: string): string {
  switch (type) {
    case "text": return "Text Question";
    case "long-text": return "Long Text Question";
    case "number": return "Number Question";
    case "select": return "Select Option";
    case "multi-select": return "Select Multiple Options";
    case "checkbox": return "Checkbox Question";
    case "image": return "Image Upload";
    case "file": return "File Upload";
    case "date": return "Date Selection";
    case "date-range": return "Date Range";
    case "signature": return "Signature";
    case "repeatable": return "Repeatable Section";
    case "instructions": return "Instructions";
    case "section": return "New Section";
    case "time": return "Time Selection";
    default: return "New Question";
  }
}

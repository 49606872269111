import { format } from 'date-fns';
import axios from 'axios';

/**
 * Send an approval or rejection notification email
 */
export const sendApprovalNotification = async (
  submission: any,
  status: 'approved' | 'rejected',
  comments: string
): Promise<boolean> => {
  try {
    const recipientEmail = submission.email;
    if (!recipientEmail) {
      console.error("Cannot send approval notification: Missing recipient email");
      return false;
    }

    // Format the date
    let submissionDate = "Unknown date";
    if (submission.submittedAt) {
      try {
        if (typeof submission.submittedAt.toDate === 'function') {
          submissionDate = format(submission.submittedAt.toDate(), 'MMM d, yyyy');
        } else if (submission.submittedAt instanceof Date) {
          submissionDate = format(submission.submittedAt, 'MMM d, yyyy');
        }
      } catch (e) {
        console.error("Error formatting date:", e);
      }
    }

    // Get form title
    const formTitle = submission.formTitle || "Untitled Form";
    
    // Define email subject and content based on status
    const subject = status === 'approved' 
      ? `Your form submission has been approved` 
      : `Your form submission requires attention`;

    // Create email HTML content
    const html = `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #eaeaea; border-radius: 5px;">
        <h2 style="color: ${status === 'approved' ? '#4caf50' : '#f44336'}; border-bottom: 1px solid #eaeaea; padding-bottom: 10px;">
          Form Submission ${status === 'approved' ? 'Approved' : 'Rejected'}
        </h2>
        
        <p>Hello,</p>
        
        <p>
          Your submission to <strong>${formTitle}</strong> from <strong>${submissionDate}</strong>
          has been ${status === 'approved' ? 'approved' : 'rejected'}.
        </p>
        
        ${comments ? `
        <div style="background-color: #f9f9f9; border-left: 4px solid ${status === 'approved' ? '#4caf50' : '#f44336'}; padding: 15px; margin: 20px 0;">
          <p style="margin: 0; font-style: italic;">${comments}</p>
        </div>
        ` : ''}
        
        <p style="margin-top: 30px; font-size: 12px; color: #666;">
          Form ID: ${submission.formId}<br>
          Submission ID: ${submission.id}<br>
          Reviewed on: ${format(new Date(), 'MMM d, yyyy')}
        </p>
      </div>
    `;

    // Plain text version for email clients that don't support HTML
    const text = `
      Form Submission ${status === 'approved' ? 'Approved' : 'Rejected'}

      Hello,

      Your submission to "${formTitle}" from ${submissionDate} has been ${status === 'approved' ? 'approved' : 'rejected'}.
      
      ${comments ? `Comments: ${comments}` : ''}

      Form ID: ${submission.formId}
      Submission ID: ${submission.id}
      Reviewed on: ${format(new Date(), 'MMM d, yyyy')}
    `;

    // Send the email using the API
    const apiUrl = process.env.NODE_ENV === 'development' 
      ? 'http://localhost:3000/api/send-email' 
      : '/api/send-email';
      
    const response = await axios.post(apiUrl, {
      email: recipientEmail,
      subject,
      html,
      text
    });

    console.log("Approval notification sent:", response.data);
    return true;
  } catch (error) {
    console.error("Error sending approval notification:", error);
    return false;
  }
};
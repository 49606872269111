import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import CommentDisplay from '../shared/CommentDisplay';
import FollowUpDisplay from '../shared/FollowUpDisplay';

interface CheckboxResponseProps {
  question: any;
  submission: any;
}

const CheckboxResponse: React.FC<CheckboxResponseProps> = ({ question, submission }) => {
  const responses = submission?.responses || {};
  const comments = submission?.comments || {};
  
  // Get comment from both potential locations
  const comment = responses[`${question.id}_comment`] || comments[question.id];
  const response = responses[question.id];
  
  // Debug logging
  console.log(`Checkbox response for ${question.id}:`, {
    response,
    type: typeof response,
    isArray: Array.isArray(response),
    checkboxStyle: question.checkboxStyle,
    hasFollowUp: !!question.followUp,
    relatedResponses: Object.keys(responses)
      .filter(key => key.startsWith(`${question.id}_`))
      .reduce((obj, key) => ({ ...obj, [key]: responses[key] }), {})
  });

  // Helper to normalize yes/no values for consistent comparison
  const normalizeYesNo = (val: any): string => {
    if (val === true || val === 'true' || val === 'yes' || val === 'Yes' || val === 'YES' || val === 'y' || val === 'Y') {
      return 'yes';
    }
    if (val === false || val === 'false' || val === 'no' || val === 'No' || val === 'NO' || val === 'n' || val === 'N') {
      return 'no';
    }
    return String(val).toLowerCase();
  };

  // Handle yes/no style checkboxes
  if (question.checkboxStyle !== 'multi') {
    // For yes-no questions, check both boolean and string responses with case-insensitive comparison
    const normalizedResponse = normalizeYesNo(response);
    const isTrue = normalizedResponse === 'yes';
    const isFalse = normalizedResponse === 'no';
    
    const displayValue = isTrue ? 'Yes' : 
                        isFalse ? 'No' : 'Not provided';
                        
    return (
      <>
        <Typography variant="body1">{displayValue}</Typography>
        
        {/* For yes/no checkbox with follow-ups - check if the condition is met regardless of type */}
        {question.followUp && isTrue && responses[`${question.id}_followUp`] && (
          <FollowUpDisplay
            question={question.followUp.question}
            response={responses[`${question.id}_followUp`]}
          />
        )}
        
        <CommentDisplay comments={comment} />
      </>
    );
  }
  
  // Handle multi-style checkboxes (similar to multi-select)
  // Handle different formats of multi-checkbox responses
  let checkboxItems: string[] = [];
  
  if (Array.isArray(response)) {
    checkboxItems = response;
  } else if (typeof response === 'object' && response !== null) {
    checkboxItems = Object.keys(response).filter(key => response[key] === true);
  } else {
    // Check for individual options stored as separate fields
    const relevantResponses = Object.keys(responses)
      .filter(key => key.startsWith(`${question.id}_`) && 
              key !== `${question.id}_other` && 
              key !== `${question.id}_comment` &&
              !key.includes('followUp'))
      .filter(key => responses[key] === true);
      
    if (relevantResponses.length > 0) {
      checkboxItems = relevantResponses.map(key => key.replace(`${question.id}_`, ''));
    }
  }
  
  if (checkboxItems.length === 0) {
    return (
      <>
        <Typography variant="body1">Not provided</Typography>
        <CommentDisplay comments={comment} />
      </>
    );
  }
  
  return (
    <>
      <Box>
        {checkboxItems.map((item, index) => (
          <Chip 
            key={index} 
            label={item} 
            size="small" 
            sx={{ mr: 0.5, mb: 0.5 }} 
          />
        ))}
      </Box>
      
      {/* Check for "other" option in checkboxes */}
      {question.allowOther && 
        (checkboxItems.includes('Other') || responses[`${question.id}_other`]) && (
        <FollowUpDisplay
          question="Other"
          response={responses[`${question.id}_other`] || '(No details provided)'}
        />
      )}
      
      {/* Handle follow-ups for multi-checkbox (if any) */}
      {checkboxItems.map(option => {
        const followUpKey = `${question.id}_followUp_${option}`;
        if (responses[followUpKey]) {
          return (
            <FollowUpDisplay 
              key={option}
              question={`Follow-up for "${option}"`}
              response={responses[followUpKey]}
            />
          );
        }
        return null;
      })}
      
      <CommentDisplay comments={comment} />
    </>
  );
};

export default CheckboxResponse;
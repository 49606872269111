import React, { useState } from "react";
import { 
  Button, 
  Menu, 
  MenuItem, 
  ListItemIcon, 
  ListItemText, 
  Typography,
  Box,
  Divider,
  Paper,
  Tooltip,
  Fade
} from "@mui/material";
import {
  Add as AddIcon,
  TextFields,
  Numbers,
  CheckBox,
  List,
  Image,
  InsertDriveFile,
  CalendarMonth,
  DateRange,
  Draw,
  Repeat,
  ViewHeadline,
  Info,
  Dashboard,
  Schedule
} from "@mui/icons-material";

interface AddQuestionButtonProps {
  onAddQuestion: (type: string) => void;
  questionTypes: string[];
}

const getIconForType = (type: string) => {
  switch (type) {
    case "text":
      return <TextFields />;
    case "number":
      return <Numbers />;
    case "select":
      return <List />;
    case "multi-select":
      return <CheckBox />;
    case "checkbox":
      return <CheckBox />;
    case "image":
      return <Image />;
    case "file":
      return <InsertDriveFile />;
    case "date":
      return <CalendarMonth />;
    case "date-range":
      return <DateRange />;
    case "signature":
      return <Draw />;
    case "repeatable":
      return <Repeat />;
    case "long-text":
      return <ViewHeadline />;
    case "instructions":
      return <Info />;
    case "section":
      return <Dashboard />;
    case "time":
      return <Schedule />;
    default:
      return <AddIcon />;
  }
};

const getQuestionTypeLabel = (type: string) => {
  switch (type) {
    case "text":
      return "Short Text";
    case "long-text":
      return "Long Text";
    case "number":
      return "Number";
    case "select":
      return "Single Select";
    case "multi-select":
      return "Multi Select";
    case "checkbox":
      return "Checkbox";
    case "date":
      return "Date";
    case "date-range":
      return "Date Range";
    case "signature":
      return "Signature";
    case "image":
      return "Image Upload";
    case "file":
      return "File Upload";
    case "repeatable":
      return "Repeatable Section";
    case "instructions":
      return "Instructions";
    case "section":
      return "Section Break";
    case "time":
      return "Time";
    default:
      return type.charAt(0).toUpperCase() + type.slice(1);
  }
};

const AddQuestionButton: React.FC<AddQuestionButtonProps> = ({ onAddQuestion, questionTypes }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddQuestion = (type: string) => {
    onAddQuestion(type);
    handleClose();
  };

  // Group question types by category
  const basicTypes = ["text", "long-text", "number", "time"];
  const choiceTypes = ["select", "multi-select", "checkbox"];
  const specialTypes = ["date", "date-range", "signature", "image", "file", "repeatable"];
  const layoutTypes = ["instructions", "section"];

  return (
    <>
      {/* Updated main Add Question button - more prominent and attention-getting */}
      <Paper 
        elevation={3} 
        sx={{ 
          my: 4, 
          py: 3, 
          px: 3,
          borderRadius: 2,
          textAlign: 'center',
          borderStyle: 'dashed',
          borderWidth: '2px',
          borderColor: 'primary.main',
          background: theme => `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
          '&:hover': {
            boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
            borderColor: 'primary.dark',
          },
          animation: 'gentle-pulse 2s infinite ease-in-out',
          transition: 'all 0.3s ease'
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClick}
          size="large"
          sx={{ 
            py: 1.5, 
            px: 4,
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            fontSize: '1rem',
            boxShadow: 4,
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: 6,
            },
            transition: 'transform 0.2s, box-shadow 0.2s'
          }}
        >
          Add Question
        </Button>
        <Typography variant="caption" color="text.secondary" display="block" mt={1}>
          Choose from various question types to build your form
        </Typography>
        <Typography variant="caption" color="primary" fontWeight="bold" display="block" mt={0.5}>
          Click here to add a new question
        </Typography>
        <style>{`
          @keyframes gentle-pulse {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-3px);
            }
            100% {
              transform: translateY(0);
            }
          }
        `}</style>
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          elevation: 3,
          sx: {
            mt: 1,
            borderRadius: 2,
            minWidth: 280,
            maxWidth: 360,
            maxHeight: '80vh',
            overflow: 'auto'
          }
        }}
      >
        <Typography variant="subtitle2" sx={{ px: 2, pt: 1.5, pb: 0.5, fontWeight: 600, color: 'text.secondary' }}>
          Basic Fields
        </Typography>
        {questionTypes.filter(type => basicTypes.includes(type)).map((type) => (
          <Tooltip key={type} title={`Add a ${getQuestionTypeLabel(type)} field`} placement="right" arrow>
            <MenuItem onClick={() => handleAddQuestion(type)}>
              <ListItemIcon sx={{ color: 'primary.main' }}>{getIconForType(type)}</ListItemIcon>
              <ListItemText primary={getQuestionTypeLabel(type)} />
            </MenuItem>
          </Tooltip>
        ))}

        <Divider sx={{ my: 1 }} />

        <Typography variant="subtitle2" sx={{ px: 2, pt: 1, pb: 0.5, fontWeight: 600, color: 'text.secondary' }}>
          Choice Fields
        </Typography>
        {questionTypes.filter(type => choiceTypes.includes(type)).map((type) => (
          <Tooltip key={type} title={`Add a ${getQuestionTypeLabel(type)} field`} placement="right" arrow>
            <MenuItem onClick={() => handleAddQuestion(type)}>
              <ListItemIcon sx={{ color: 'primary.main' }}>{getIconForType(type)}</ListItemIcon>
              <ListItemText primary={getQuestionTypeLabel(type)} />
            </MenuItem>
          </Tooltip>
        ))}

        <Divider sx={{ my: 1 }} />

        <Typography variant="subtitle2" sx={{ px: 2, pt: 1, pb: 0.5, fontWeight: 600, color: 'text.secondary' }}>
          Special Fields
        </Typography>
        {questionTypes.filter(type => specialTypes.includes(type)).map((type) => (
          <Tooltip key={type} title={`Add a ${getQuestionTypeLabel(type)} field`} placement="right" arrow>
            <MenuItem onClick={() => handleAddQuestion(type)}>
              <ListItemIcon sx={{ color: 'primary.main' }}>{getIconForType(type)}</ListItemIcon>
              <ListItemText primary={getQuestionTypeLabel(type)} />
            </MenuItem>
          </Tooltip>
        ))}

        <Divider sx={{ my: 1 }} />

        <Typography variant="subtitle2" sx={{ px: 2, pt: 1, pb: 0.5, fontWeight: 600, color: 'text.secondary' }}>
          Layout Elements
        </Typography>
        {questionTypes.filter(type => layoutTypes.includes(type)).map((type) => (
          <Tooltip key={type} title={`Add a ${getQuestionTypeLabel(type)}`} placement="right" arrow>
            <MenuItem onClick={() => handleAddQuestion(type)}>
              <ListItemIcon sx={{ color: 'primary.main' }}>{getIconForType(type)}</ListItemIcon>
              <ListItemText primary={getQuestionTypeLabel(type)} />
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>
    </>
  );
};

export default AddQuestionButton;
import { format } from 'date-fns';

/**
 * Format a date from various potential formats
 */
export const formatDate = (date: any): string => {
  try {
    if (typeof date?.toDate === 'function') {
      return format(date.toDate(), 'MMM d, yyyy');
    } else if (date instanceof Date) {
      return format(date, 'MMM d, yyyy');
    }
  } catch (error) {
    console.error("Error formatting date:", error);
  }
  return 'Unknown Date';
};

/**
 * Helper for status colors
 */
export const getStatusColor = (status: string, isBorder: boolean = false): string => {
  const alpha = isBorder ? 1 : 0.8; // Border is more solid
  
  switch (status) {
    case 'approved':
      return `rgba(46, 125, 50, ${alpha})`;  // Green
    case 'rejected':
      return `rgba(198, 40, 40, ${alpha})`;  // Red
    case 'pending':
    default:
      return `rgba(237, 108, 2, ${alpha})`;  // Orange/Amber
  }
};

/**
 * Process various timestamp formats to Date objects
 */
export const processTimestamp = (value: any): Date | null => {
  if (!value) return null;
  
  try {
    // Case 1: Already a JavaScript Date object
    if (value instanceof Date) {
      return value;
    }
    
    // Case 2: Firestore Timestamp object with toDate method
    if (typeof value.toDate === 'function') {
      return value.toDate();
    }
    
    // Case 3: Serialized timestamp with seconds
    if (typeof value === 'object' && value !== null && value.seconds !== undefined) {
      return new Date(value.seconds * 1000);
    }
    
    // Case 4: ISO string or other parseable string
    if (typeof value === 'string') {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
    
    // Case 5: Numeric timestamp in milliseconds
    if (typeof value === 'number') {
      return new Date(value);
    }
    
    console.warn("Unrecognized date format:", value);
    return null;
  } catch (err) {
    console.error("Error processing timestamp:", err);
    return null;
  }
};
import React from 'react';
import { 
  Paper, Box, Typography, FormControl, InputLabel, 
  Select, MenuItem, Chip, SelectChangeEvent
} from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

interface User {
  id: string;
  email: string;
  name: string;
}

interface AdminUserFilterProps {
  users: User[];
  selectedUserId: string;
  onUserChange: (event: SelectChangeEvent<string>) => void;
}

const AdminUserFilter: React.FC<AdminUserFilterProps> = ({
  users,
  selectedUserId,
  onUserChange
}) => {
  return (
    <Paper 
      elevation={0}
      sx={{ 
        p: { xs: 2, sm: 2 }, 
        mb: { xs: 2, sm: 3 }, 
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <AccountCircle sx={{ mr: 1, color: 'text.secondary' }} />
        <Typography variant="subtitle2" color="text.secondary">
          Admin View:
        </Typography>
      </Box>
      
      <FormControl sx={{ minWidth: 220, flexGrow: { xs: 1, sm: 0 } }} size="small">
        <InputLabel id="user-filter-label">Filter by Form Creator</InputLabel>
        <Select
          labelId="user-filter-label"
          value={selectedUserId}
          label="Filter by Form Creator"
          onChange={onUserChange}
        >
          <MenuItem value="">
            <em>All Users</em>
          </MenuItem>
          {users.map(user => (
            <MenuItem key={user.id} value={user.id}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body2">{user.name}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {user.email}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      {selectedUserId && (
        <Chip 
          label={`Viewing ${users.find(u => u.id === selectedUserId)?.name || 'user'}'s forms`}
          onDelete={() => onUserChange({ target: { value: "" } } as SelectChangeEvent<string>)}
          color="primary"
          variant="outlined"
          size="small"
        />
      )}
      
      {!selectedUserId && (
        <Chip 
          label="Viewing all users' forms"
          color="default"
          variant="outlined"
          size="small"
        />
      )}
    </Paper>
  );
};

export default AdminUserFilter;
import React from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions,
  Box, Typography, Divider, TextField, Button
} from '@mui/material';
import { Cancel } from '@mui/icons-material';

interface RejectionDialogProps {
  open: boolean;
  onClose: () => void;
  onReject: () => void;
  selectedSubmission: any;
  comments: string;
  onCommentsChange: (value: string) => void;
  processingAction: boolean;
}

const RejectionDialog: React.FC<RejectionDialogProps> = ({
  open,
  onClose,
  onReject,
  selectedSubmission,
  comments,
  onCommentsChange,
  processingAction
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => !processingAction && onClose()}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ pb: 1 }}>
        Reject Submission
      </DialogTitle>
      
      <DialogContent>
        <Box sx={{ mb: 2, mt: 1 }}>
          <Typography variant="subtitle2" color="text.secondary">
            Submission from
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {selectedSubmission?.email || 'Anonymous'}
          </Typography>
        </Box>
        
        <Divider sx={{ mb: 2 }} />
        
        <Typography variant="body1" sx={{ mb: 2 }}>
          Please provide a reason for rejection:
        </Typography>
        
        <TextField
          multiline
          rows={4}
          fullWidth
          value={comments}
          onChange={(e) => onCommentsChange(e.target.value)}
          placeholder="Enter the reason for rejection..."
          variant="outlined"
          required
          error={open && !comments.trim()}
          helperText={open && !comments.trim() ? "Rejection reason is required" : ""}
        />
      </DialogContent>
      
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button 
          onClick={onClose} 
          disabled={processingAction}
          color="inherit"
        >
          Cancel
        </Button>
        <Button 
          onClick={onReject}
          variant="contained"
          color="error"
          disabled={processingAction || !comments.trim()}
          startIcon={<Cancel />}
        >
          {processingAction ? "Processing..." : "Reject"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectionDialog;
import { db } from '../../firebase';
import {
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
  writeBatch,
  deleteField
} from 'firebase/firestore';

/**
 * Records a new form submission in the database with full response details
 */
export const recordFormSubmission = async (
  formId: string,
  email: string,
  fullResponses?: any, 
  repeatableItems?: { [key: string]: Array<{ [key: string]: any }> }
): Promise<string> => {
  try {
    console.log(`Recording submission for form ${formId}`);
    
    // First, get the form to check if approval is required
    const formRef = doc(db, "forms", formId);
    const formSnap = await getDoc(formRef);
    const formData = formSnap.exists() ? formSnap.data() : {};
    
    // Check if form requires approval
    const requiresApproval = formData.requiresApproval === true;
    
    // 1. Add to the main submissions collection
    const submissionData = {
      formId,
      formTitle: formData.formTitle || "Untitled Form",
      userId: formData.userId,
      email,
      submittedAt: serverTimestamp(),
      // Add approval fields based on the form setting
      approvalRequired: requiresApproval,
      approvalStatus: requiresApproval ? "pending" : "approved",
      approvalComments: "",
      approvalDate: requiresApproval ? null : serverTimestamp(),
      // CRITICAL: Always include archived field set to false for new submissions
      archived: false
    };
    
    const submissionRef = await addDoc(collection(db, "form_submissions"), submissionData);
    console.log("Recorded submission with ID:", submissionRef.id);
    
    // 2. Store full response data in a separate collection if provided
    if (fullResponses) {
      // Extract repeatableItems from fullResponses if not provided separately
      const extractedRepeatableItems = repeatableItems || {};
      
      await setDoc(doc(db, "submission_details", submissionRef.id), {
        submissionId: submissionRef.id,
        formId,
        formTitle: formData.formTitle || "Untitled Form", // Include form title here too
        email,
        submittedAt: serverTimestamp(),
        responses: cleanObjectForFirestore(fullResponses),
        repeatableItems: cleanObjectForFirestore(extractedRepeatableItems),
        // Include approval fields
        approvalRequired: requiresApproval,
        approvalStatus: requiresApproval ? "pending" : "approved",
        approvalComments: "",
        approvalDate: requiresApproval ? null : serverTimestamp(),
        // CRITICAL: Always include archived field set to false for new submissions
        archived: false,
        metadata: {
          userAgent: navigator?.userAgent || '',
          submitDevice: navigator?.userAgent && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) 
            ? 'mobile' : 'desktop',
        }
      });
      console.log("Recorded full response details");
    }
    
    // 3. Update form stats
    try {
      await updateFormStats(formId, email);
    } catch (statsError) {
      console.error("Error updating form stats:", statsError);
    }
    
    return submissionRef.id;
  } catch (error) {
    console.error("Error recording submission:", error);
    throw error;
  }
};

/**
 * Records a new form submission with approval fields
 */
export const recordFormSubmissionWithApproval = async (
  formId: string,
  email: string,
  fullResponses?: any, 
  repeatableItems?: { [key: string]: Array<{ [key: string]: any }> },
  approvalData?: {
    approvalRequired: boolean;
    approvalStatus: 'pending' | 'approved' | 'rejected';
    approvalComments?: string;
    approvalDate?: Date | null;
  }
): Promise<string> => {
  try {
    console.log(`Recording submission with approval data for form ${formId}`);
    
    // Get form data to include form title
    const formRef = doc(db, "forms", formId);
    const formSnap = await getDoc(formRef);
    const formData = formSnap.exists() ? formSnap.data() : {};
    
    // 1. Add to the main submissions collection with approval fields
    const submissionData = {
      formId,
      formTitle: formData.formTitle || "Untitled Form", // Add form title
      userId: formData.userId, // Include user ID
      email,
      submittedAt: serverTimestamp(),
      // Add approval fields
      approvalRequired: approvalData?.approvalRequired || false,
      approvalStatus: approvalData?.approvalStatus || "approved",
      approvalComments: approvalData?.approvalComments || "",
      approvalDate: approvalData?.approvalDate || null,
      // CRITICAL: Always include archived field set to false for new submissions
      archived: false
    };
    
    const submissionRef = await addDoc(collection(db, "form_submissions"), submissionData);
    console.log("Recorded submission with ID:", submissionRef.id);
    
    // 2. Store full response data in a separate collection if provided
    if (fullResponses) {
      // Extract repeatableItems from fullResponses if not provided separately
      const extractedRepeatableItems = repeatableItems || {};
      
      await setDoc(doc(db, "submission_details", submissionRef.id), {
        submissionId: submissionRef.id,
        formId,
        formTitle: formData.formTitle || "Untitled Form", // Add form title
        userId: formData.userId, // Include user ID
        email,
        submittedAt: serverTimestamp(),
        responses: cleanObjectForFirestore(fullResponses),
        repeatableItems: cleanObjectForFirestore(extractedRepeatableItems),
        // Include approval fields
        approvalRequired: approvalData?.approvalRequired || false,
        approvalStatus: approvalData?.approvalStatus || "approved",
        approvalComments: approvalData?.approvalComments || "",
        approvalDate: approvalData?.approvalDate || null,
        // CRITICAL: Always include archived field set to false for new submissions
        archived: false,
        metadata: {
          userAgent: navigator?.userAgent || '',
          submitDevice: navigator?.userAgent && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) 
            ? 'mobile' : 'desktop',
        }
      });
      console.log("Recorded full response details");
    }
    
    // 3. Update form stats
    try {
      await updateFormStats(formId, email);
    } catch (statsError) {
      console.error("Error updating form stats:", statsError);
    }
    
    return submissionRef.id;
  } catch (error) {
    console.error("Error recording submission:", error);
    throw error;
  }
};

/**
 * Updates the form stats document with a new submission
 */
export const updateFormStats = async (formId: string, email: string): Promise<void> => {
  try {
    const formStatsRef = doc(db, "formStats", formId);
    
    // Get current stats
    const formStatsDoc = await getDoc(formStatsRef);
    
    if (formStatsDoc.exists()) {
      const existingStats = formStatsDoc.data();
      
      // Make sure we have a valid response count
      const currentCount = typeof existingStats.responseCount === 'number' 
        ? existingStats.responseCount 
        : 0;
      
      // Update existing stats
      await updateDoc(formStatsRef, {
        responseCount: currentCount + 1,
        lastSubmission: new Date(),
        lastSubmitterEmail: email,
        submitterEmails: Array.isArray(existingStats.submitterEmails)
          ? [...existingStats.submitterEmails, email]
          : [email],
        updatedAt: new Date()
      });
    } else {
      // Create new stats document
      await setDoc(formStatsRef, {
        formId,
        responseCount: 1,
        firstSubmission: new Date(),
        lastSubmission: new Date(),
        createdAt: new Date(),
        submitterEmails: [email],
        lastSubmitterEmail: email
      });
    }
    
    console.log(`Updated stats for form ${formId}`);
  } catch (error) {
    console.error("Error updating form stats:", error);
    throw error;
  }
};

/**
 * Duplicates a form with a new title and optional new email and user ID
 */
export const duplicateForm = async (
  formId: string, 
  newTitle: string, 
  newEmail?: string, 
  newUserId?: string,
  adminData?: { id: string, email: string }
) => {
  try {
    // Get the original form
    const formRef = doc(db, "forms", formId);
    const formSnap = await getDoc(formRef);
    
    if (!formSnap.exists()) {
      throw new Error("Form not found");
    }
    
    // Get form data
    const formData = formSnap.data();
    
    // Create new form object with modified properties
    const newFormData: any = {
      ...formData,
      formTitle: newTitle,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      // Override email if provided, otherwise keep original
      email: newEmail || formData.email,
      // Override userId if provided, otherwise keep original
      userId: newUserId || formData.userId,
    };
    
    // Add duplication metadata
    newFormData.duplicatedFrom = {
      formId: formId,
      duplicatedAt: serverTimestamp(),
      originalTitle: formData.formTitle
    };
    
    // Add assignment metadata if this is being assigned to a different user by an admin
    if (newUserId && newUserId !== formData.userId && adminData) {
      newFormData.assignedByAdmin = {
        adminId: adminData.id,
        adminEmail: adminData.email,
        assignmentDate: serverTimestamp()
      };
    } else {
      // Make sure the assignedByAdmin field is not included if not needed
      delete newFormData.assignedByAdmin;
    }
    
    // Create the new form document
    const newFormRef = await addDoc(collection(db, "forms"), newFormData);
    console.log(`Form duplicated successfully with ID: ${newFormRef.id}`);
    return newFormRef.id;
  } catch (error) {
    console.error("Error duplicating form:", error);
    throw error;
  }
};

/**
 * Toggle archive status of a submission
 */
export const toggleSubmissionArchiveStatus = async (submissionId: string): Promise<boolean> => {
  try {
    // 1. Update the main submission record
    const submissionRef = doc(db, "form_submissions", submissionId);
    const submissionDoc = await getDoc(submissionRef);
    
    if (!submissionDoc.exists()) {
      console.error(`Submission ${submissionId} not found`);
      return false;
    }
    
    const currentArchiveStatus = submissionDoc.data().archived || false;
    const newArchiveStatus = !currentArchiveStatus;
    
    // Update with new archive status
    await updateDoc(submissionRef, {
      archived: newArchiveStatus,
      archivedAt: newArchiveStatus ? serverTimestamp() : null
    });
    
    // 2. Also update the submission details record
    const detailsRef = doc(db, "submission_details", submissionId);
    const detailsDoc = await getDoc(detailsRef);
    
    if (detailsDoc.exists()) {
      await updateDoc(detailsRef, {
        archived: newArchiveStatus,
        archivedAt: newArchiveStatus ? serverTimestamp() : null
      });
    }
    
    console.log(`Successfully ${newArchiveStatus ? 'archived' : 'unarchived'} submission ${submissionId}`);
    return true;
  } catch (error) {
    console.error(`Error toggling archive status for submission ${submissionId}:`, error);
    return false;
  }
};

/**
 * Ensures all submissions have the archived field set
 * Useful for migrating old data
 * Admin-only function
 */
export const ensureArchiveField = async (formId: string): Promise<void> => {
  try {
    console.log(`Ensuring archive field for form ${formId}`);
    
    // Get all submissions
    const submissionsQuery = query(
      collection(db, "form_submissions"),
      where("formId", "==", formId)
    );
    
    const submissionsSnapshot = await getDocs(submissionsQuery);
    
    // Batch update submissions missing the field
    const batch = writeBatch(db);
    let updateCount = 0;
    
    submissionsSnapshot.docs.forEach(doc => {
      const data = doc.data();
      if (data.archived === undefined) { // Only update if field doesn't exist
        console.log(`Adding archive field to submission ${doc.id}`);
        batch.update(doc.ref, { archived: false });
        updateCount++;
      }
    });
    
    if (updateCount > 0) {
      await batch.commit();
      console.log(`Updated ${updateCount} submissions`);
    } else {
      console.log("No submissions needed updating");
    }
    
    // Now do the same for submission_details
    const detailsQuery = query(
      collection(db, "submission_details"),
      where("formId", "==", formId)
    );
    
    const detailsSnapshot = await getDocs(detailsQuery);
    
    const detailsBatch = writeBatch(db);
    let detailsUpdateCount = 0;
    
    detailsSnapshot.docs.forEach(doc => {
      const data = doc.data();
      if (data.archived === undefined) {
        console.log(`Adding archive field to submission detail ${doc.id}`);
        detailsBatch.update(doc.ref, { archived: false });
        detailsUpdateCount++;
      }
    });
    
    if (detailsUpdateCount > 0) {
      await detailsBatch.commit();
      console.log(`Updated ${detailsUpdateCount} submission details`);
    }
    
  } catch (error) {
    console.error("Error ensuring archive field:", error);
    throw error;
  }
};

/**
 * Remove the reset status from a submission and update form statistics
 * @param submissionId The ID of the submission to restore
 * @returns Promise resolving to true if successful
 */
export const removeSubmissionResetStatus = async (submissionId: string): Promise<boolean> => {
  try {
    const submissionRef = doc(db, "form_submissions", submissionId);
    const submissionDoc = await getDoc(submissionRef);
    
    if (!submissionDoc.exists()) {
      console.error(`Submission ${submissionId} not found`);
      return false;
    }
    
    const submissionData = submissionDoc.data();
    const formId = submissionData.formId;
    
    // If submission is archived, removing reset status won't affect the count
    const isArchived = submissionData.archived === true;
    
    // Remove the resetAt field
    await updateDoc(submissionRef, {
      resetAt: deleteField()
    });
    
    // Only update the count if the submission is not archived
    if (!isArchived) {
      // Get the current formStats document
      const formStatsRef = doc(db, "formStats", formId);
      const formStatsSnap = await getDoc(formStatsRef);
      
      if (formStatsSnap.exists()) {
        // Increment the responseCount by 1
        await updateDoc(formStatsRef, {
          responseCount: increment(1),
          updatedAt: serverTimestamp()
        });
        
        console.log(`Updated response count for form ${formId}`);
      } else {
        // If formStats doesn't exist, create it with correct initial value
        // We need to count all active submissions
        const activeSubmissionsQuery = query(
          collection(db, "form_submissions"),
          where("formId", "==", formId),
          where("archived", "!=", true)
        );
        
        const activeSubmissions = await getDocs(activeSubmissionsQuery);
        
        await setDoc(formStatsRef, {
          responseCount: activeSubmissions.size,
          totalSubmissionCount: activeSubmissions.size,
          formId: formId,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
        
        console.log(`Created formStats for ${formId} with ${activeSubmissions.size} active submissions`);
      }
    }
    
    return true;
  } catch (error) {
    console.error(`Error removing reset status for submission ${submissionId}:`, error);
    return false;
  }
};

/**
 * Remove reset status from all submissions for a specific form and update form statistics
 * @param formId The ID of the form
 * @returns Promise resolving to the number of submissions restored
 */
export const removeAllFormResetStatus = async (formId: string): Promise<number> => {
  try {
    // First get all submissions for this form - no filter on resetAt to avoid needing complex index
    const submissionsRef = collection(db, "form_submissions");
    const q = query(
      submissionsRef,
      where("formId", "==", formId)
    );
    
    const querySnapshot = await getDocs(q);
    console.log(`Found ${querySnapshot.size} total submissions for form ${formId}`);
    
    // Filter client-side for submissions with resetAt
    const resetSubmissions = querySnapshot.docs.filter(doc => {
      const data = doc.data();
      return data.resetAt !== undefined && data.resetAt !== null;
    });
    
    console.log(`Found ${resetSubmissions.length} reset submissions to restore`);
    
    if (resetSubmissions.length === 0) {
      return 0;
    }
    
    // Use batched writes for better performance
    const batch = writeBatch(db);
    let count = 0;
    const maxBatchSize = 500; // Firestore batch limit
    
    resetSubmissions.forEach(doc => {
      batch.update(doc.ref, {
        resetAt: deleteField()
      });
      count++;
      
      // Firestore limits batch operations to 500
      if (count >= maxBatchSize && count < resetSubmissions.length) {
        // Commit when we reach the limit and create a new batch
        batch.commit();
        const newBatch = writeBatch(db);
      }
    });
    
    // Only commit if we have operations to perform
    if (count > 0) {
      await batch.commit();
    }
    
    // Now update the formStats document with accurate counts
    // Count all active (non-archived, non-reset) submissions
    let activeCount = 0;
    
    querySnapshot.docs.forEach(doc => {
      const data = doc.data();
      // Treat as if we've just removed all resetAt fields
      const isArchived = data.archived === true;
      if (!isArchived) {
        activeCount++;
      }
    });
    
    // Update the formStats document
    const formStatsRef = doc(db, "formStats", formId);
    const formStatsSnap = await getDoc(formStatsRef);
    
    if (formStatsSnap.exists()) {
      await updateDoc(formStatsRef, {
        responseCount: activeCount,
        lastResetAt: deleteField(),
        responseCountBeforeReset: deleteField(),
        updatedAt: serverTimestamp()
      });
    } else {
      // If formStats doesn't exist, create it
      await setDoc(formStatsRef, {
        responseCount: activeCount,
        totalSubmissionCount: querySnapshot.size,
        formId: formId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
    }
    
    console.log(`Updated form stats for ${formId}: ${activeCount} active submissions`);
    
    return count;
  } catch (error) {
    console.error(`Error removing reset status for form ${formId}:`, error);
    throw error;
  }
};

/**
 * Recalculate and update form statistics based on actual submission counts
 * @param formId The ID of the form to update statistics for
 * @returns Promise resolving to the updated response count
 */
export const recalculateFormStats = async (formId: string): Promise<number> => {
  try {
    // Count all submissions (for total)
    const allSubmissionsQuery = query(
      collection(db, "form_submissions"),
      where("formId", "==", formId)
    );
    
    const allSubmissionsSnap = await getDocs(allSubmissionsQuery);
    const totalCount = allSubmissionsSnap.size;
    
    // Use client-side filtering to avoid complex queries
    let activeCount = 0;
    
    allSubmissionsSnap.docs.forEach(doc => {
      const data = doc.data();
      // Count as active if it's not archived AND not reset
      if (data.archived !== true && !data.resetAt) {
        activeCount++;
      }
    });
    
    console.log(`Form ${formId} has ${activeCount} active submissions out of ${totalCount} total`);
    
    // Update the formStats document
    const formStatsRef = doc(db, "formStats", formId);
    
    await setDoc(formStatsRef, {
      responseCount: activeCount,
      totalSubmissionCount: totalCount,
      formId: formId,
      updatedAt: serverTimestamp()
    }, { merge: true });
    
    console.log(`Updated form stats for ${formId}: ${activeCount} active submissions`);
    
    return activeCount;
  } catch (error) {
    console.error(`Error recalculating form stats for ${formId}:`, error);
    throw error;
  }
};

/**
 * Reset an individual submission (mark with resetAt timestamp)
 * @param submissionId The ID of the submission to reset
 * @returns Promise resolving to a boolean indicating success
 */
export const resetIndividualSubmission = async (submissionId: string): Promise<boolean> => {
  try {
    // Get the submission document reference
    const submissionRef = doc(db, "form_submissions", submissionId);
    
    // Update the document to add resetAt timestamp
    await updateDoc(submissionRef, {
      resetAt: serverTimestamp()
    });
    
    // Get the submission to find its formId
    const submissionSnap = await getDoc(submissionRef);
    const submissionData = submissionSnap.data();
    
    if (submissionData && submissionData.formId) {
      // Recalculate the form stats to update the count
      await recalculateFormStats(submissionData.formId);
    }
    
    return true;
  } catch (error) {
    console.error("Error resetting individual submission:", error);
    return false;
  }
};

/**
 * Helper function to clean objects for Firestore
 */
function cleanObjectForFirestore(obj: any): any {
  // If it's not an object or is null, return it directly
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  
  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.map(item => cleanObjectForFirestore(item));
  }
  
  // Handle objects
  const cleanedObj: any = {};
  for (let key in obj) {
    // Skip functions
    if (typeof obj[key] === 'function') {
      continue;
    }
    
    // Handle complex objects like Date
    if (obj[key] instanceof Date) {
      cleanedObj[key] = obj[key];
    }
    // Handle other objects recursively
    else if (obj[key] !== null && typeof obj[key] === 'object') {
      cleanedObj[key] = cleanObjectForFirestore(obj[key]);
    }
    // Handle primitive values
    else if (typeof obj[key] !== 'function') {
      cleanedObj[key] = obj[key];
    }
    // Skip functions and other non-serializable values
  }
  
  return cleanedObj;
}

function increment(value: number): any {
  return { increment: value };
}
